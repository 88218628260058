<template>
    <div class="detail-wrap">
        <div class="content">
            <!-- 基础信息 -->
            <sv-card title="基础信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="baseForm" :labelWidth="120">
                        <item type="row">
                            <item type="col">
                                <item label="反馈单号" type="displayfield" name="id" />
                                <item label="反馈时间" type="displayfield" name="createdOn" />
                                <item label="技术支持联系人" type="displayfield" name="technicianMan" />
                                <item label="技术支持电话" type="displayfield" name="technicianTel" />
                            </item>
                            <item type="col">
                                <item label="经销商简称" type="displayfield" name="dealerAs" />
                                <item label="经销商代码" type="displayfield" name="dealerCode" />
                                <item label="经销商名称" type="displayfield" name="dealerName" />
                            </item>
                        </item>
                    </sv-panel-form>
                    <div class="footer"></div>
                </div>
            </sv-card>

            <!-- 车辆信息 -->
            <sv-card title="车辆信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="carForm" :labelWidth="120">
                        <item type="row">
                            <item type="col">
                                <item label="VIN" span="24" type="displayfield" name="vin" />
                            </item>
                            <item type="col">
                                <item label="车型" type="displayfield" name="modelPlatformName" />
                                <item label="生产日期" type="displayfield" name="productDate" />
                                <item label="里程" type="displayfield" name="mileage" />
                                <item label="购车时间" type="displayfield" name="buyDate" />
                            </item>
                        </item>
                    </sv-panel-form>
                    <div class="footer"></div>
                </div>
            </sv-card>

            <!-- 故障处理过程 -->
            <sv-card title="故障处理过程" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="faultForm" :labelWidth="120">
                        <item type="row">
                            <item type="col">
                                <item label="故障描述" span="24" type="displayfield" name="faultDesc" />
                            </item>
                            <item type="col">
                                <item label="系统查询SC" type="displayfield" name="systemSelectSc" span="8" />
                                <item label="系统查询SKC" type="displayfield" name="systemSelectSkc" span="16" />
                                <!-- <item label="日期" type="displayfield" name="faultDealWithDate" />
                                <item label="时间" type="displayfield" name="faultDealWithDatestr" /> -->
                            </item>
                            <item type="col">
                                <item label="描述" span="24" type="displayfields" name="processingDesc" />
                            </item>
                        </item>
                    </sv-panel-form>
                    <div class="footer"></div>
                </div>
            </sv-card>

            <!-- 诊断仪相关信息 -->
            <sv-card title="诊断仪相关信息" :showLoading="showLoading">
                <div slot="body">
                    <sv-panel-form ref="dsForm" :labelWidth="130">
                        <item type="row">
                            <item type="col">
                                <item label="诊断仪账户" type="displayfield" name="dsAccount" />
                                <item label="诊断注册手机" type="displayfield" name="dsMobile" />
                            </item>
                            <item type="col">
                                <item label="故障码" span="24" type="displayfield" name="faultCode" />
                            </item>
                            <item type="col">
                                <item label="原零件号" type="displayfield" name="oldPartCode" />
                                <item label="新零件号" type="displayfield" name="newPartCode" />
                            </item>
                            <item type="col">
                                <item label="原零件/标签图片" span="24" type="image" name="oldpartResIds" />
                            </item>
                            <item type="col">
                                <item label="新零件/标签图片" span="24" type="image" name="newpartResIds" />
                            </item>
                        </item>
                    </sv-panel-form>
                    <div class="footer"></div>
                </div>
            </sv-card>

            <!-- 读取PEPSECU信息诊断仪截图 -->
            <sv-card title="读取PEPSECU信息诊断仪截图" :showLoading="showLoading">
                <div slot="body" style="text-align: left" v-if="data.pepsecuResIds">
                    <sv-image
                        v-for="item in data.pepsecuResIds"
                        :key="item.path"
                        :width="100"
                        :height="100"
                        :src="item.path"
                        :isShowDownload="false"></sv-image>
                    <div class="footer"></div>
                </div>
                <div v-else>无数据</div>
            </sv-card>

            <!-- 读取防盗匹配相关数据 -->
            <sv-card title="读取防盗匹配相关数据" :showLoading="showLoading">
                <div slot="body">
                    <div class="footer">
                        {{ data.pepsecuDesc || '无数据' }}
                    </div>
                </div>
            </sv-card>

            <!-- 咨询历史 -->
            <ChatInfo
                :isEdit="true"
                @submited="loadBaseData"
                :loadUrl="`/consult/ds/feedback/chat/history?id=${$route.query.id}`"
                submitUrl="/consult/ds/feedback/chat/reply"
                url="/api/consult/res/upload" />
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import ChatInfo from './common/chatInfo.vue';

export default {
    name: 'M100-1',
    components: {
        ChatInfo
    },
    data() {
        return {
            title: document.title,
            showLoading: false,
            data: {}
        };
    },

    computed: {},

    created() {
        this.loadBaseData();
    },

    methods: {
        loadBaseData() {
            const url = '/consult/ds/feedback/detail?id=' + this.$route.query.id;
            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        this.data = result;
                        this.$nextTick(() => {
                            this.$refs.baseForm.setValues(result);
                            this.$refs.carForm.setValues(result.dsFeedbackVin);
                            this.$refs.faultForm.setValues(result);
                            this.$refs.dsForm.setValues(result);

                            const str = result.processingDesc || '无数据';
                            const replacedStr = str.replace(/\n/g, '<br>');
                            const replacedStrs = `<div>${replacedStr}</div>`

                            this.$refs.faultForm.setFieldValue('processingDesc', replacedStrs || '无数据');
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        }
    }
};
</script>

<style lang="less" scoped>
.detail-wrap {
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    min-width: 1200px;

    .header {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #4e4e4e;
        height: 34px;
        line-height: 34px;
        padding: 0 6px;
        font-weight: 600;
        background: #e4e4e4;

        span {
            margin-right: 50px;
        }
    }

    .info-bar {
        display: flex;
        height: 40px;
        line-height: 40px;
        background: #fff;
        padding: 0 5px;
        align-items: center;
        justify-content: space-between;

        .bills {
            margin-right: 10px;
            font-size: 16px;
            color: #111627;
            font-weight: 600;
        }

        .status {
            display: inline-block;
            height: 22px;
            line-height: 22px;
            margin-right: 10px;
            padding: 0px 7px;
            border-radius: 4px;
            background-color: #ecf7ff;
            color: #129fff;
        }

        .buttons>* {
            margin-right: 5px;
        }
    }

    .content {
        flex: 1;
        padding: 5px 0;
    }

    /deep/ .image-class {
        height: 100%;
    }

    /deep/.ivu-card-head {
        background: #e4e4e4;
        text-align: left !important;
        padding-left: 10px;
    }

    /deep/.images-wrap {
        margin-right: 10px;
    }

    /deep/ .displayfields-class {
        height: 100%;
    }
}
</style>
