<template>
    <sv-card title="历史交流">
        <div slot="body" class="chat-wrap">
            <!-- 内容框 -->
            <div class="content-wrap" v-viewer>
                <div v-for="(item, idx) in chatItmes" :key="idx">
                    <div class="item-wrap">
                        <div class="right">
                            <div class="header">
                                <span class="send-name">{{ item.createdBy }}</span>
                                <span class="send-time">{{ item.createdOn }}</span>
                            </div>
                            <div class="content" v-html="item.content"></div>
                        </div>
                    </div>
                </div>

                <!-- 无记录 -->
                <div class="no-record" v-if="chatItmes.length === 0">无记录</div>
            </div>

            <!-- 聊天框 -->
            <Form :model="form" label-position="left" :label-width="100">
                <FormItem label="问题回复：">
                    <Input
                        id="ipt"
                        v-model="form.input"
                        type="textarea"
                        :rules="{
                            required: true,
                            message: '请添加问题回复内容',
                            trigger: 'blur'
                        }"></Input>
                </FormItem>

                <FormItem label="附件：">
                    <addUpload
                        btnUplodText="浏览"
                        class="btn-upload"
                        url="/api/consult/res/upload"
                        @onSuccess="handleUpload"
                        @handleAdd="handleAdd"
                        :formDynamic="formDynamic">
                    </addUpload>
                </FormItem>
            </Form>
            <Button type="primary" class="btn" @click="doReply">发送</Button>

            <!-- showLoading -->
            <Spin size="large" v-if="showLoading" fix></Spin>
        </div>
    </sv-card>
</template>

<script>
import Vue from 'vue';
import { request } from '@/network/request';
import VueViewer from 'v-viewer';
import addUpload from './add-upload.vue';

VueViewer.setDefaults({
    toolbar: {
        prev: true,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true
    },
    loop: false,
    title: false
});
Vue.use(VueViewer);

export default {
    components: {
        addUpload
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        loadUrl: {
            type: String
        },
        submitUrl: {
            type: String
        }
    },
    data() {
        return {
            editorData: null,
            chatItmes: [],
            showLoading: false,
            loadingStatus: false,
            data: {},
            fileData: '',
            fileData1: [],
            form: {
                input: '',
                fileList: ''
            },
            index: 1,
            formDynamic: [
                {
                    value: '',
                    index: 1
                }
            ],
            lists: [],
            ids: '2022112700008',
            attachment: [],
            arr: [],
            arr1: [],
            fileName: []
        };
    },
    mounted() {
        this.loadChatContent();
    },
    methods: {
        /**
         * 回复
         */
        doReply() {
            const params = this.getParams();

            if (params.content && params.content.length) {
                this.showLoading = true;
                params.content = this.editorData + this.fileData;
                debugger;

                request
                    .post(this.submitUrl, params)
                    .then(data => {
                        if (data.success) {
                            this.loadChatContent();
                            this.reset();
                            this.$emit('submited');
                        } else {
                            this.$Message.error('操作失败: ' + data.message);
                        }
                    })
                    .finally(() => {
                        this.showLoading = false;
                    });
            } else {
                this.$Message.error('备注内容不能为空！');
            }
        },

        /**
         * 获取参数
         */
        getParams() {
            const id = this.ids;
            this.editorData = this.form.input;

            return {
                dsFeedbackId: id,
                content: this.editorData,
                resId: this.attachment
            };
        },

        /**
         * 加载对话内容
         */
        loadChatContent() {
            const url = this.loadUrl + '?id=' + this.ids;

            this.showLoading = true;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.chatItmes = data.result || [];
                    } else {
                        this.$Message.error('加载失败: ' + data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        /**
         * 处理上传附件
         */
        handleUpload(data, file, fileList, item) {
            this.data = data;

            if (data.success) {
                // 解决附件重复上传的问题
                this.arr.push(item.index);

                let indexLists = [...new Set(this.arr)];

                if (indexLists.includes(item.index)) {
                    this.lists[item.index - 1] = file;
                } else {
                    this.lists.push(file);
                }

                // 展示上传文件
                const responseList = this.lists.map(item => item.response);

                // 传给后端文件字段
                this.fileData = '';
                this.fileName = [];
                responseList.forEach(item => {
                    const { name, bytes, path } = item.result[0];

                    this.fileName.push(`${name}`);
                    // 所有添加的附件
                    const fileSize = (bytes / 1024 / 1024).toFixed(1);
                    this.fileData += `<span class="download-attachment-wrap"></br>
                    <i class="iconfont icon-attachment"></i>
                    <a href="${path}?dl=true"  target="_blank" download>下载附件(${name}, ${fileSize == 0.0 ? 0.1 : fileSize
                        }MB) </a>
                    </span>`;
                });

                this.formDynamic.forEach(item => {
                    this.fileName.forEach((item1, index) => {
                        if (item.index == index + 1) {
                            this.formDynamic[index].value = item1;
                        }
                    });
                });

                const resultList = responseList.map(item => item.result[0]);
                this.attachment = resultList.map(item => item.id);

                this.$Message.success('文件上传成功');
            } else {
                this.$Message.error(data.message);
            }
        },

        handleAdd() {
            this.index++;
            if (this.formDynamic.length < 5) {
                this.formDynamic.push({
                    value: '',
                    index: this.index
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        },

        reset() {
            this.form = {
                input: '',
                fileList: ''
            };
            this.formDynamic = [
                {
                    value: '',
                    index: 1
                }
            ];
        }
    }
};
</script>

<style lang="less" scoped>
/deep/.ivu-card-body {
    padding: 0;
}

.chat-wrap {
    display: flex;
    flex-direction: column;
    position: relative;

    .content-wrap {
        padding: 5px;

        .item-wrap {
            display: flex;
            flex-direction: row;

            &.flex-end {
                justify-content: end;
            }

            .left {
                display: flex;
                flex-direction: column;
                margin-bottom: 15px;
                background: #ebf4ff;
                border-radius: 5px;
                width: 50%;

                .header {
                    padding: 8px 5px;
                    border-bottom: 1px solid #d4e4f6;
                }

                .content {
                    padding: 3px 15px;

                    .send-name {
                        font-size: 12px;
                        font-weight: 600;
                        margin: 5px;
                    }

                    .send-time {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                border-bottom: 1px #d4e4f6 solid;

                width: 100%;

                .header {
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 0px 5px 15px;
                    width: 100%;
                }

                .content {
                    padding: 3px 15px;
                    text-align: left;

                    .send-name {
                        font-size: 12px;
                        font-weight: 600;
                        margin: 5px;
                    }

                    .send-time {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .ivu-form {
        padding: 0 30px;

        .ivu-form-item {
            margin-bottom: 10px;

            /deep/.btn-upload {
                margin-left: 54px !important;
            }
        }
    }

    .btns-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;

        /deep/ .ivu-btn-small {
            height: 28px;
            padding: 0px 16px;
        }
    }
}

.btn {
    width: 65px;
    margin: 0 auto;
}
</style>
