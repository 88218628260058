<template>
    <div class="content-wrap">
        <sv-grid
            ref="grid"
            listTitle="购物车列表"
            :rownumber="true"
            multiSelect
            @inputNumberChange="inputNumberChange"
            @selectionChange="selectionChange"
            @tableCellBtnClick="tableCellBtnClick"
            @rowClick="rowClick"
            @deleteRow="deleteRow">
            <urls>
                <item name="read" url="/mh/shopping/cart/page" />
                <item name="export" url="/mh/export/shopping_cart" />
            </urls>
            <div slot="grid-middle1" class="shopping-cart-top">
                <div class="total-info">
                    <div class="total-price">
                        总价：<span class="price">￥{{ totalPrice }}</span>
                    </div>
                    <div class="goods">合计商品：{{ totalNum }}件</div>
                </div>
            </div>
            <toolbars>
                <item name="export" icon="md-download" text="导出清单" permission='tis:shopping-cart:export' />
            </toolbars>
            <columns>
                <item title="类别分类" key="categoryClassifyName" width="120px" />
                <item title="配件分类" key="partClassify" width="120px" />
                <item title="车型" key="modelPlatformName" width="120px" />
                <item title="配件名称" key="partName" width="140px" />
                <item title="配件编号" key="partCode" width="140px" />
                <item title="单车用量" key="consumptionPerCar" width="120px" />
                <item title="单件参考价格" key="referencePrice" width="120px" />
                <item
                    title="数量"
                    key="orderNumber"
                    type="numberbox"
                    :min="1"
                    :minWidth="110"
                    width="150px"
                    align="center" />
                <item title="操作" key="operation" type="deleteIcon" flex="1" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';
import { request } from '@/network/request';

export default {
    mixins: [crud],
    data() {
        return {
            totalPrice: 0,
            totalNum: 0
        };
    },
    created() { },
    mounted() {
        this.$refs.grid.readRecord();
    },
    methods: {
        exportRecord() {
            this.$refs.grid.exportRecord();
        },
        inputNumberChange(row, value) {
            let params = {
                id: row.id,
                orderNumber: value
            };

            request
                .post('/mh/shopping/cart/addNum', params)
                .then(data => {
                    if (data.success) {
                        //先初始化list值再load数据解决inputNumber数据不对
                        this.$refs.grid.dataList = [];
                        this.$refs.grid.readRecord();
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        },
        selectionChange(selection) {
            let total = 0;
            let totalPrice = 0;

            selection.forEach(item => {
                total += item.orderNumber;
                totalPrice += item.referencePrice * item.orderNumber;
            });

            this.totalNum = total;
            this.totalPrice = totalPrice;
        },

        tableCellBtnClick(row) {
            let params = { listIds: [row.id] };
            request
                .req({
                    method: 'POST',
                    url: '/cart/remove',
                    data: params
                })
                .then(data => {
                    if (data.success) {
                        this.$refs.grid.loadData();
                        this.$eventBus.$emit('shopping-cart-change');
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        },
        rowClick(data) {
            this.totalNum = data.orderNumber;
            this.totalPrice = data.referencePrice * data.orderNumber;
        },
        deleteRow(row) {
            const url = '/mh/shopping/cart/delete';
            let params = {
                id: row.id
            };

            request
                .post(url, params)
                .then(data => {
                    if (data.success) {
                        this.$refs.grid.dataList = [];
                        this.$refs.grid.readRecord();
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    /deep/.grid-title {
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn {
        color: #005ad5;
        line-height: 20px;
    }

    /deep/.ivu-table-cell {
        line-height: 38px;
    }
}
</style>
