<template>
    <div class="content-wrap">
        <!-- 查询区域 -->
        <sv-search @onSearch="doSearch" ref="search" :labelWidth="100">
            <item label="软件名称" type="textbox" name="name" />
            <item label="软件号" type="textbox" name="code" />
            <item label="版本号" type="textbox" name="version" />
            <item label="零件号" type="textbox" name="partCode" />
            <item label="零件名称" type="textbox" name="partName" />
            <!-- <item label="品牌" type="combobox" url="/manual/combo/brand/list" name="brandCode" />
            <item
                label="车系"
                type="combobox"
                url="/manual/combo/series/list"
                name="seriesCode"
                :autoLoad="false"
                :expandLoad="true"
                dependParamsKey="brandCode"
                dependParams="brandCode"
            /> -->
            <item label="VIN" type="textbox" name="vin" />
            <item label="关键词" type="textbox" name="subject" />
        </sv-search>
        <!-- 表格区域 -->
        <sv-grid ref="grid" listTitle="诊断仪软件" :rownumber="true" @getDataList="getDataList">
            <div slot="grid-top" class="total">
                共<span class="total-num">{{ total }}</span> 条数据
            </div>
            <urls>
                <item name="read" url="/ds/diagnostics/software/page" />
            </urls>
            <toolbars> </toolbars>
            <columns>
                <!-- <item title="软件号" key="code" width="120px" /> -->
                <item title="软件名称" key="name" width="120px" />
                <item title="版本号" key="useVersion" width="120px" />
                <item title="活动主题" key="subject" width="120px" />
                <item title="零件号" key="partCode" width="120px" />
                <item title="配件名称" key="partName" width="120px" />
                <!-- <item title="品牌" key="brandName" width="120px" />
                <item title="车系名称" key="seriesName" width="120px" /> -->
                <item title="操作" key="" flex="1" :render="getOperateRender" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';
import { request } from '@/network/request';
import { downloadFile } from '@/common/utils';

export default {
    mixins: [crud],
    data() {
        return {
            total: 0
        };
    },
    created() {},
    mounted() {},
    methods: {
        getOperateRender(h, { row }) {
            return h(
                'a',

                {
                    directives: [
                        {
                            name: 'permission',
                            value: 'tis:ds:one-export'
                        }
                    ],
                    on: {
                        click: () => {
                            this.download(row);
                        }
                    }
                },
                '下载'
            );
        },
        download(row) {
            const url = '/ds/diagnostics/software/downLoad?id=' + row.versionId;

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        const { result } = data;
                        downloadFile(result.path, result.name + '.' + result.extension);
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        },
        getDataList(data) {
            if (data.success) {
                this.total = data.result.total;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    padding: 0 20px;

    /deep/.query-inner {
        border-bottom: 1px @border-color solid;
    }

    /deep/.grid-title {
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn-default {
        color: #005ad5;
        line-height: 20px;
    }

    .total {
        margin-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Regular;

        .total-num {
            color: #b60004;
        }
    }
}
</style>
