<template>
    <div class="announcement-wrap">
        <div class="main">
            <h2 class="page-title">消息</h2>
            <div class="content-wrap">
                <div class="status-wrap">
                    <div class="item-wrap">
                        <span class="label">全部：</span>
                        <span class="text">{{ total }}</span>
                    </div>
                </div>

                <div class="list-wrap">
                    <div v-if="list.length">
                        <div class="item-wrap" v-for="(item, idx) in list" :key="idx">
                            <div class="subject-wrap" @click="handleJump(item.jumpLinkInfo)">
                                <span class="title-wrap">{{ item.messageContent }}</span>
                                <div class="publish-date">
                                    <span>{{ item.sendTime }}</span>
                                    <Icon type="md-arrow-forward" size="18" style="margin-left: 10px;" color="#1f59a8" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 无数据 -->
                    <NoRecord v-else titleZh="暂无公告信息" />
                </div>

                <Page
                    class="page-wrap"
                    :total="pagingData.total"
                    :current="pagingData.page"
                    :pageSize="pagingData.size"
                    :pageSizeOpts="pagingData.pageSizeOpts"
                    @on-change="gotoPage"
                    @on-page-size-change="pageSizeOnChange"
                    show-sizer
                    show-total />

                <!-- 加载层 -->
                <Spin size="large" fix v-if="showLoading" />
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/common';
import NoRecord from '@/pages/common/noRecord.vue';

export default {
    name: 'home',
    components: {
        NoRecord
    },
    created() {
        this.loadData();
    },
    watch: {
        list(val) {
            this.renderList = JSON.parse(JSON.stringify(val));
        }
    },
    computed: {
        total() {
            return this.pagingData.total || 0;
        }
    },
    data() {
        return {
            list: [],
            renderList: [],
            keyword: '',
            showLoading: false,
            pagingData: {
                page: 1,
                size: 10,
                total: 0,
            },
        };
    },
    methods: {
        loadData() {
            const params = this.getParams();

            request
                .get('/consult/info/message/list', params)
                .then(data => {
                    if (data.success) {
                        this.pagingData.total = data.result.total;
                        this.list = data.result.list;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getParams() {
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams({}, page, size);

            return params;
        },
        // 消息跳转
        handleJump(path) {
            if (!path) return;
            let routeData = this.$router.resolve({ path });
            window.open(routeData.href, '_blank');
        },

        gotoPage(page) {
            this.pagingData.page = page;
            this.loadData();
        },

        pageSizeOnChange(size) {
            this.pagingData.page = 1;
            this.pagingData.size = size;
            this.loadData();
        },
    }
};
</script>

<style lang="less" scoped>
.announcement-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: @body-bg-color;

    .main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;
        height: 100%;

        .page-title {
            margin: 10px 0;
        }

        .content-wrap {
            min-width: 900px;
            position: relative;
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 0px;
            border: 1px @border-color solid;

            .status-wrap {
                display: flex;
                padding: 8px;
                background: #fff;
                border-bottom: 1px solid @border-color;
                align-items: center;

                .item-wrap {
                    margin-right: 10px;

                    .label {
                        color: #ccc;
                    }
                }

                .unread-status {
                    .label {
                        color: #ccc;
                    }

                    .unread-dot {
                        color: #bb0e94;
                        margin-right: 3px;
                    }
                }

                .split-line {
                    width: 1px;
                    height: 15px;
                    border: 1px solid #ddd;
                    margin-right: 10px;
                }
            }

            .list-wrap {
                flex: 1;
                padding: 10px;
                background: #fff;
                overflow-y: auto;

                .item-wrap {
                    margin-bottom: 10px;
                    border-bottom: 1px solid @border-color;

                    .subject-wrap {
                        display: flex;
                        align-items: center;

                        .title-wrap {
                            flex: 1;
                            position: relative;
                            padding: 6px 0;
                        }

                        .publish-date {
                            width: 190px;
                        }
                    }
                }
            }
        }
    }
}

.page-wrap {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-bottom: 10px;
}

/deep/ .ivu-collapse-content-box {
    padding: 8px !important;
}
</style>
