<template>
    <div class="announcement-wrap">
        <div class="main">
            <h2 class="page-title">通知</h2>
            <div class="content-wrap">
                <div class="status-wrap">
                    <div class="item-wrap">
                        <span class="label">全部：</span>
                        <span class="text">{{ pagingData.total }}</span>
                    </div>
                    <div class="split-line"></div>
                    <div class="unread-status">
                        <span class="unread-dot">●</span>
                        <span class="label">未读：</span>
                        <span class="text">{{ unReadTotal }}</span>
                    </div>
                    <div class="split-line"></div>
                    <div class="search-list">
                        <span class="text1"
                            ><span class="label">已/未读：</span>
                            <Select
                                v-model="searchModel.isRead"
                                style="width: 150px"
                                clearable
                                :disabled="hiddenSourceType"
                            >
                                <Option value="是">已读</Option>
                                <Option value="否">未读</Option>
                            </Select></span
                        >
                        <span class="text1"
                            ><span class="label">关键词：</span>
                            <Input v-model="searchModel.keyword" placeholder="请输入关键词" style="width: 150px" />
                        </span>
                        <span class="text1"
                            ><span class="label">发布时间：</span>
                            <DatePicker
                                v-model="searchModel.startDate"
                                style="width: 80px"
                                format="yyyy-MM-dd"
                                clearable
                            />
                            -
                            <DatePicker
                                v-model="searchModel.endDate"
                                style="width: 80px"
                                format="yyyy-MM-dd"
                                clearable
                            />
                        </span>

                        <br />
                        <div style="margin: 10px 0"></div>
                        <span class="text1"
                            ><span class="label">通知编码：</span>
                            <Input v-model="searchModel.informCode" placeholder="请输入通知编码" style="width: 150px" />
                        </span>
                    </div>
                </div>

                <div class="list-wrap">
                    <Collapse v-if="renderList.length">
                        <Panel
                            :class="{ unread: !item.readTime }"
                            v-for="(item, idx) in list"
                            :name="item.name"
                            :key="idx"
                            @click.native="handleRead(item)"
                        >
                            <div class="subject-wrap">
                                <span
                                    class="title-wrap text-ellipsis"
                                    :title="(item.informCode || '') + '' + item.messageTitle"
                                    >{{ (item.informCode || '') + '-' + item.messageTitle
                                    }}<span v-if="!item.readTime" class="unread-dot">●</span></span
                                >
                                <span class="publish-date">{{ formatDatetime(item.sendTime) }}</span>
                            </div>

                            <div slot="content">
                                <div class="text-wrap" v-html="item.messageContent" />
                                <div class="attach-wrap" v-if="item.res && item.res.length">
                                    <div class="attach-item" v-for="file in item.res" :key="file.id">
                                        <span class="attach-icon">
                                            <span class="icon iconfont icon-link"></span>
                                        </span>
                                        <span class="attach-name"
                                            >{{ file.name + '.' + file.extension }}
                                            <span class="attach-size">({{ getSize(file.bytes) }})</span></span
                                        >
                                        <a
                                            v-if="previewableFileTypes.includes(file.extension)"
                                            :href="`/pre-view?url=${file.path}&type=${file.extension}`"
                                            target="_blank"
                                            class="attach-download"
                                            >预览</a
                                        >
                                        <a
                                            v-else-if="file.extension === 'pdf'"
                                            :href="`/pdf-view?url=${file.path}`"
                                            target="_blank"
                                            class="attach-download"
                                            >预览</a
                                        >
                                        <span
                                            class="attach-download"
                                            @click="handleDowmload(file, file.name, file.extension)"
                                            >下载</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>

                    <!-- 无数据 -->
                    <NoRecord v-else titleZh="暂无公告信息" />
                </div>

                <Page
                    class="page-wrap"
                    :total="pagingData.total"
                    :current="pagingData.page"
                    :pageSize="pagingData.size"
                    :pageSizeOpts="pagingData.pageSizeOpts"
                    @on-change="gotoPage"
                    @on-page-size-change="pageSizeOnChange"
                    show-sizer
                    show-total
                />

                <!-- 加载层 -->
                <Spin size="large" fix v-if="showLoading" />
            </div>
        </div>
    </div>
</template>

<script>
import { request, instance } from '@/common';
import NoRecord from '@/pages/common/noRecord.vue';
import { downloadFile } from '@/common/utils';
export default {
    name: 'home',
    components: {
        NoRecord
    },
    data() {
        return {
            list: [],
            renderList: [],
            showLoading: false,
            searchModel: {
                keyword: '',
                isRead: '',
                startDate: '',
                endDate: '',
                informCode: ''
            },
            pagingData: {
                page: 1,
                size: 20,
                total: 0,
                pageSizeOpts: this.pageSizeOpts
            },
            unReadTotal: 0,
            hiddenSourceType: false,
            disabledSourceType: false,
            previewableFileTypes: ['jpg', 'jpeg', 'png', 'gif', 'svg', 'mp4', 'avi', 'mkv', 'webm', 'mp3']
        };
    },
    created() {
        const { sourceType, isRead } = this.$route.query;
        if (sourceType) {
            this.searchModel.sourceType = sourceType;
            this.disabledSourceType = true;
        }
        if (isRead) {
            this.searchModel.isRead = isRead;
        }
        this.loadData();
    },
    mounted() {
        this.loadDataDbs = this.getDebounce(this.loadData, 500);
    },
    watch: {
        list(val) {
            this.renderList = JSON.parse(JSON.stringify(val));
        },
        // isRead() {
        //     this.loadDataDbs();
        //     this.renderList = JSON.parse(JSON.stringify(this.list));
        // },
        // sourceType() {
        //     this.loadDataDbs();
        //     this.renderList = JSON.parse(JSON.stringify(this.list));
        // },
        // keyword() {
        //     this.loadDataDbs();
        //     this.renderList = JSON.parse(JSON.stringify(this.list));
        // },
        searchModel: {
            deep: true,
            handler() {
                this.loadDataDbs();
                this.renderList = JSON.parse(JSON.stringify(this.list));
            }
        }
    },
    // computed: {
    //     total() {
    //         return this.renderList.length || 0;
    //     }
    // },

    methods: {
        loadData() {
            const params = this.getParams();

            request
                .get('/consult/info/message/page', params)
                .then(data => {
                    if (data.success) {
                        this.pagingData.total = data.result.total;
                        this.unReadTotal = data.result.unReadTotal;
                        this.list = data.result.list;
                        this.list.filter(item => {
                            if (!item.readTime) {
                                this.infoListLength += 1;
                            }
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        },

        getParams() {
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams(this.searchModel, page, size);

            return params;
        },

        getSize(size) {
            let unit;
            let units = ['B', 'K', 'M', 'G', 'TB'];
            while ((unit = units.shift()) && size > 1024) {
                size = size / 1024;
            }
            return (unit === 'B' ? size : size.toFixed(2)) + unit;
        },

        gotoPage(page) {
            this.pagingData.page = page;
            this.loadData();
        },

        pageSizeOnChange(size) {
            this.pagingData.page = 1;
            this.pagingData.size = size;
            this.loadData();
        },

        handleRead(item) {
            if (item.readTime) return;

            const params = { id: item.id };

            this.showLoading = true;

            request
                .post('/consult/info/message/readMessage', params)
                .then(data => {
                    if (!data.success) {
                        this.$Message.error(`操作失败: ${data.message}`);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });

            this.$nextTick(() => {
                this.loadData();
            });
        },
        handleDowmload(file, name, extension) {
            if (extension === 'pdf') {
                const url = '/consult/info/message/downloadFile?uri=' + file.uri;
                instance
                    .get(url, {
                        responseType: 'blob'
                    })
                    .then(response => {
                        const data = new Blob([response]);
                        const url = URL.createObjectURL(data);
                        // 创建一个链接
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = name + '.' + extension;

                        // 添加到DOM中，模拟点击下载链接
                        document.body.appendChild(link);
                        link.click();

                        // 移除下载链接
                        document.body.removeChild(link);
                        URL.revokeObjectURL(url);
                    });
            } else {
                downloadFile(file.path, name + '.' + extension);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.announcement-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: @body-bg-color;

    .main {
        display: flex;
        flex-direction: column;
        width: 70%;
        margin: 0 auto;
        height: 100%;

        .page-title {
            margin: 10px 0;
        }

        .content-wrap {
            min-width: 900px;
            flex: 1;
            position: relative;
            display: flex;

            flex-direction: column;
            min-height: 0px;
            border: 1px @border-color solid;

            .status-wrap {
                display: flex;
                padding: 8px;
                background: #fff;
                border-bottom: 1px solid @border-color;
                align-items: center;

                .item-wrap {
                    margin-right: 10px;

                    .label {
                        color: #ccc;
                    }
                }

                .unread-status {
                    padding-right: 10px;

                    .label {
                        color: #ccc;
                    }

                    .unread-dot {
                        color: #bb0e94;
                        margin-right: 3px;
                    }
                }

                .search-list {
                    flex: 1;

                    .label {
                        display: inline-block;
                        text-align: right;
                        width: 80px;
                        color: #ccc;
                        vertical-align: middle;
                    }

                    .text1 {
                        color: #9b9696;
                        line-height: 32px;
                        // margin-right: 20px;
                    }

                    margin-left: 5px;
                }

                .split-line {
                    width: 1px;
                    height: 15px;
                    border: 1px solid #ddd;
                    margin-right: 10px;
                }
            }

            .list-wrap {
                flex: 1;
                padding: 10px;
                background: #fff;
                overflow-y: auto;

                .unread {
                    /deep/ .ivu-collapse-header {
                        font-weight: 600;
                    }
                }

                .subject-wrap {
                    display: flex;
                    align-items: center;

                    .title-wrap {
                        flex: 1;
                        position: relative;

                        .unread-dot {
                            position: absolute;
                            color: #bb0e94;
                            margin-left: 5px;
                        }
                    }

                    .publish-date {
                        width: 190px;
                        margin-left: 20px;
                    }
                }

                .attach-wrap {
                    padding: 5px 0px;

                    .attach-item {
                        display: flex;
                        align-items: center;
                        margin-right: 10px;

                        .attach-icon {
                            margin-right: 5px;
                            color: #4a8deb;
                        }

                        .attach-name {
                            color: #333;
                            font-size: 14px;

                            .attach-size {
                                color: #999;
                                font-size: 12px;
                                margin-left: 5px;
                            }
                        }

                        .attach-download {
                            color: #4a8deb;
                            border: 1px solid #4a8deb;
                            margin-left: 10px;
                            padding: 0 5px;
                            font-size: 12px;
                            cursor: pointer;

                            a {
                                padding: 0 5px;
                                color: #4a8deb;
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-wrap {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-bottom: 10px;
}

/deep/ .ivu-collapse-content-box {
    padding: 8px !important;
}
</style>
