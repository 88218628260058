<template>
    <div class="content-wrap">
        <!-- 查询区域 -->
        <sv-search @onSearch="doSearch" ref="search" :labelWidth="100">
            <item label="反馈编码" type="textbox" url="" name="id" />
            <item label="VIN" type="textbox" url="" name="vin" />
            <item label="原零件号" type="textbox" name="oldPartCode" />
            <item label="新零件号" type="textbox" name="newPartCode" />
            <item label="反馈时间起" type="textbox" name="startDate" />
            <item label="反馈时间止" type="textbox" name="endDate" />
            <item label="经销商代码" type="textbox" name="dealerCode" />
            <item label="经销商名称" type="textbox" name="dealerName" />
            <item label="诊断仪账户" type="textbox" name="dsAccount" />
        </sv-search>

        <!-- 表格区域 -->
        <sv-grid
            ref="grid"
            listTitle="诊断仪反馈"
            :rownumber="true"
            @onToolbarClick="handleToolbarClick"
            @getDataList="getDataList">
            <div slot="grid-top" class="total">
                共<span class="total-num">{{ total }}</span> 条数据
            </div>
            <urls>
                <item name="read" url="/consult/ds/feedback/page" />
            </urls>
            <toolbars>
                <item name="addFeedback" text="新增反馈" :permission="'tis:ds-feedback:add'"></item>
            </toolbars>
            <columns>
                <item title="反馈编码" key="id" width="120px" linkUrl="/diagnosereply/detail?id={id}" />
                <item title="问题状态" key="statusName" width="120px" />
                <item title="反馈时间" key="createdOn" width="140px" />
                <item title="VIN" key="vin" width="120px" />
                <item title="原零件号" key="oldPartCode" width="120px" />
                <item title="新零件号" key="newPartCode" width="120px" />
                <item title="经销商代码" key="dealerCode" width="120px" />
                <item title="经销商" key="dealerName" width="120px" />
                <item title="诊断仪账户" key="dsAccount" flex="1" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';
// import { request } from '@/network/request';

export default {
    mixins: [crud],
    data() {
        return {
            total: 0
        };
    },
    created() { },
    mounted() {
        this.$refs.grid.readRecord();
    },
    methods: {
        handleToolbarClick(name) {
            // 拿到当编辑行的数据
            // const row = this.$refs.grid.getSelection();

            if (name === 'addFeedback') {
                let routeData = this.$router.resolve({ path: '/diagnosereply/newDiagnosereply' });
                window.open(routeData.href, '_blank');
            }
        },
        getDataList(data) {
            if (data.success) {
                this.total = data.result.total;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    padding: 0 20px;

    /deep/.query-inner {
        border-bottom: 1px @border-color solid;
    }

    /deep/.grid-title {
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn-default {
        color: #005ad5;
        line-height: 20px;
    }

    .total {
        margin-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Regular;

        .total-num {
            color: #b60004;
        }
    }
}
</style>
