<template>
    <div class="content-wrap">
        <div class="main">
            <!-- 基础信息 -->
            <div class="baseForm">
                <div class="title">基础信息</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right" :rules="ruleValidate">
                        <Row>
                            <Col span="6">
                            <FormItem label="技术支持联系人：" :label-width="140" prop="technicianMan">
                                <Input v-model="form.technicianMan" />
                            </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="技术支持电话：" :label-width="140" prop="technicianTel"><Input
                                    v-model="form.technicianTel" />
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">
                            <FormItem label="经销商简称：" :label-width="140" prop="dealerAs"><Input
                                    v-model="form.dealerAs" /> </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="经销商代码：" :label-width="140" prop="dealerCode"><Input
                                    v-model="form.dealerCode" /> </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="经销商名称：" :label-width="120" prop="dealerName">
                                <Input v-model="form.dealerName" />
                            </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <!-- 车辆信息 -->
            <div class="baseForm">
                <div class="title">车辆信息</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right" :rules="ruleValidate">
                        <Row>
                            <Col span="6">
                            <FormItem label="VIN：" :label-width="120" prop="dsFeedbackVin.vin">
                                <Input v-model="form.dsFeedbackVin.vin" />
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">
                            <FormItem label="车型：" :label-width="120">
                                <Select v-model="form.dsFeedbackVin.modelPlatformName">
                                    <Option v-for="item in modelList" :value="item.code" :key="item.code">{{
                                        item.name
                                    }}</Option>
                                </Select>
                            </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="生产日期：" :label-width="120">
                                <DatePicker
                                    type="date"
                                    placeholder="请选择日期"
                                    v-model="form.dsFeedbackVin.productDate"></DatePicker>
                            </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="里程(KM)：" :label-width="120"><Input v-model="form.dsFeedbackVin.mileage" />
                            </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="购车时间：" :label-width="120">
                                <DatePicker
                                    type="date"
                                    placeholder="请选择日期"
                                    v-model="form.dsFeedbackVin.buyDate"></DatePicker>
                            </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <!-- 故障处理过程 -->
            <div class="baseForm">
                <div class="title">故障处理过程</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right" :rules="ruleValidate">
                        <Row>
                            <Col span="24">
                            <FormItem label="故障描述：" :label-width="120" prop="faultDesc"><Input type="textarea"
                                    v-model="form.faultDesc" /> </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="8">
                            <FormItem label="系统查询SC：" :label-width="120"><Input v-model="form.systemSelectSc" />
                            </FormItem>
                            </Col>
                            <Col span="16">
                            <FormItem label="系统查询SKC：" :label-width="120"><Input v-model="form.systemSelectSkc" />
                            </FormItem>
                            </Col>
                            <!-- <Col span="6">
                            <FormItem label="日期：" :label-width="120">
                                <DatePicker
                                    type="date"
                                    placeholder="请选择日期"
                                    v-model="form.faultDealWithDate"></DatePicker>
                            </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="时间：" :label-width="120">
                                <Input v-model="form.faultDealWithDatestr" />
                            </FormItem>
                            </Col> -->
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="描述：" :label-width="120"><Input type="textarea" rows="6"
                                    v-model="form.processingDesc" />
                            </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <!-- 诊断仪相关信息 -->
            <div class="baseForm">
                <div class="title">诊断仪相关信息</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right" :rules="ruleValidate">
                        <Row>
                            <Col span="6">
                            <FormItem label="诊断仪账户：" :label-width="130" prop="dsAccount"><Input
                                    v-model="form.dsAccount" /> </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="诊断注册手机：" :label-width="130" prop="dsMobile"><Input
                                    v-model="form.dsMobile" /> </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="故障码：" :label-width="130"><Input v-model="form.faultCode" /> </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="6">
                            <FormItem label="原零件号：" :label-width="130"><Input v-model="form.oldPartCode" /> </FormItem>
                            </Col>
                            <Col span="6">
                            <FormItem label="新零件号：" :label-width="130"><Input v-model="form.newPartCode" /> </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="原零件/标签图片：">
                                <addUpload
                                    btnUplodText="浏览"
                                    class="btn-upload"
                                    url="/api/consult/res/upload"
                                    :formDynamic="formDynamic1"
                                    @handleUpload="handleUpload"
                                    @handleAdd="handleAdd1(formDynamic1)">
                                </addUpload>
                            </FormItem>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                            <FormItem label="新零件/标签图片：">
                                <addUpload
                                    btnUplodText="浏览"
                                    class="btn-upload"
                                    url="/api/consult/res/upload"
                                    :formDynamic="formDynamic2"
                                    @handleUpload="handleUpload2"
                                    @handleAdd="handleAdd2(formDynamic2)">
                                </addUpload>
                            </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <!-- 读取PEPSECU信息诊断仪截图 -->
            <div class="baseForm">
                <div class="title">读取PEPSECU信息诊断仪截图：</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right">
                        <Row>
                            <Col span="24">
                            <FormItem label="附件：">
                                <addUpload
                                    btnUplodText="浏览"
                                    class="btn-upload"
                                    url="/api/consult/res/upload"
                                    :formDynamic="formDynamic"
                                    @handleUpload="handleUpload"
                                    @handleAdd="handleAdd(formDynamic)">
                                </addUpload>
                            </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>

            <!-- 读取防盗匹配相关数据 -->
            <div class="baseForm">
                <div class="title">读取防盗匹配相关数据</div>
                <div class="detail">
                    <Form ref="form" :model="form" label-position="right">
                        <Row>
                            <Col span="24">
                            <FormItem label="数据：" :label-width="60"><Input v-model="form.pepsecuDesc" /> </FormItem>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Button class="btn" type="primary" @click="doSubmit">提交反馈</Button>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import nopic from '@/assets/images/nopic.png';
import addUpload from './add-upload.vue';

export default {
    components: {
        addUpload
    },
    data() {
        return {
            data: {},
            nopic: nopic,
            hasSupersession: true,
            form: {
                technicianMan: '',
                technicianTel: '',
                dealerAs: '',
                dealerCode: '',
                dealerName: '',
                faultDesc: '',
                processingDesc: '',
                systemSelectSc: '',
                systemSelectSkc: '',
                faultDealWithDate: '',
                faultDealWithDatestr: '',
                dsAccount: '',
                dsMobile: '',
                faultCode: '',
                oldPartCode: '',
                newPartCode: '',
                oldpartResIds: [],
                newpartResIds: [],
                pepsecuResIds: [],
                pepsecuDesc: '',
                dsFeedbackVin: {
                    vin: '',
                    modelPlatformCode: '',
                    modelPlatformName: '',
                    productDate: '',
                    mileage: '',
                    buyDate: ''
                }
            },
            ruleValidate: {
                technicianMan: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                technicianTel: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                dealerAs: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                dealerCode: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                dealerName: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                'dsFeedbackVin.vin': [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                faultDesc: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                dsAccount: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ],
                dsMobile: [
                    { required: true, message: '请填写数据', trigger: 'blur' }
                ]
            },
            files: [],
            formDynamic: [
                {
                    value: '',
                    index: 1
                }
            ],
            index: 1,
            formDynamic1: [
                {
                    value: '',
                    index: 1
                }
            ],
            index1: 1,
            formDynamic2: [
                {
                    value: '',
                    index: 1
                }
            ],
            index2: 1,
            modelList: [],
        };
    },
    created() {
        this.getModelList();
    },
    watch: {
        'form.dealerCode'(val) {
            if (!val) return;
            this.getNameFunc();
        }
    },
    mounted() {
        this.getNameFunc = this.getDebounce(this.getName, 1000)
    },
    methods: {
        getName() {
            const code = this.form.dealerCode;
            const url = `/consult/ds/feedback/enterprise/detail?code=${code}`

            request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.form.dealerAs = data.result.shortName;
                        this.form.dealerName = data.result.name;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        },

        getModelList() {
            request
                .get('/mh/quality/part/models')
                .then(data => {
                    if (data.success) {
                        this.modelList = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        },
        async doSubmit() {
            const params = this.form;

            const valid = await this.validateForm(this.form);

            if (!valid) {
                this.$Message.error('请填写信息再提交');
                return;
            }

            request
                .post('/consult/ds/feedback/create', params)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('提交成功');
                        this.$router.push('/diagnosereply');
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .catch(() => { });
        },
        validateForm(obj) {
            let flag = false;

            for (let key in obj) {
                if (Array.isArray(obj[key])) {
                    if (obj[key].length > 0) {
                        flag = true;
                    }
                } else if (obj[key].constructor === Object) {
                    flag = this.validateForm(obj[key]) ? true : flag;
                } else if (obj[key] !== '') {
                    flag = true;
                }
            }

            return flag;
        },
        handleUpload(res) {
            this.form.pepsecuResIds = res;
        },
        handleUpload1(res) {
            this.form.oldpartResIds = res;
        },
        handleUpload2(res) {
            this.form.newpartResIds = res;
        },
        handleAdd(list) {
            if (list.slice(-1)[0].value === '') {
                this.$Message.error('请先上传附件');
                return;
            }

            this.index++;
            if (list.length < 5) {
                list.push({
                    value: '',
                    index: this.index
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        },
        handleAdd1(list) {
            if (list.slice(-1)[0].value === '') {
                this.$Message.error('请先上传附件');
                return;
            }

            this.index1++;
            if (list.length < 5) {
                list.push({
                    value: '',
                    index: this.index1
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        },
        handleAdd2(list) {
            if (list.slice(-1)[0].value === '') {
                this.$Message.error('请先上传附件');
                return;
            }

            this.index2++;
            if (list.length < 5) {
                list.push({
                    value: '',
                    index: this.index2
                });
            } else {
                this.$Message.error('最多只能添加5个附件');
            }
        },
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    width: 100%;
    height: 100%;
    background-color: #ffffff;

    .main {
        width: 70%;
        height: 100%;
        min-width: 1200px;
        margin: 0 auto;

        .baseForm {
            padding: 5px 0px;
            margin-top: 10px;
            border: 1px @border-color solid;

            .title {
                padding: 0 0 5px 10px;
                border-bottom: 1px @border-color solid;

                font-size: 17px;
                color: #00176b;
                font-weight: 600;

                .number,
                .time,
                .status {
                    line-height: 25px;
                    font-size: 14px;
                    color: #00176b;
                    font-weight: 400;
                }
            }

            .detail {
                display: flex;

                .ivu-form {
                    margin: 10px 15px;
                    width: 100%;

                    .ivu-row .btn-upload {
                        margin-left: 130px;
                    }

                    /deep/.txtarea {
                        textarea {
                            width: 100%;
                            height: 100px;
                        }
                    }

                    .list {
                        border: 1px @border-color solid;
                        padding: 0 10px;
                        background-color: #f8f8f8;

                        .item {
                            border-bottom: 1px @border-color solid;
                        }

                        .item:last-child {
                            border: none;
                        }
                    }
                }
            }

            .btn {
                margin: 0 auto;
                display: block;
            }
        }
    }

    /deep/.ivu-row {
        margin-bottom: 10px;
    }
}
</style>
