<template>
    <div class="login-wrapper">
        <div class="login-form">
            <div class="login-logo">
                <img src="@/assets/images/logo.png" alt="" />
            </div>
            <div class="login-cube">
                <div class="login-title">
                    <h2>北京汽车售后技术信息查询平台(TIS)</h2>
                </div>
                <Form ref="loginForm" :model="loginForm" :rules="rules">
                    <FormItem prop="user" class="form-item">
                        <Input
                            type="text"
                            v-model="loginForm.user"
                            placeholder="用户名"
                            @on-enter="doLogin"
                            size="large"
                            class="input">
                        <Icon type="ios-contact" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem prop="password" class="form-item">
                        <Input
                            type="password"
                            v-model="loginForm.password"
                            placeholder="密码"
                            @on-enter="doLogin"
                            size="large"
                            class="input">
                        <Icon type="md-lock" slot="prepend"></Icon>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <Button class="login-button" long @click="doLogin" :loading="btnLoading">登 录</Button>
                    </FormItem>

                    <Button class="f-button" type="primary" @click="forgetPSW">忘记密码？</Button>
                </Form>
            </div>
        </div>
    </div>
</template>

<script>
import { request } from '@/network/request';
import { storage } from '@/common/storage';
import { encrypt } from '@/common/rsaEncrypt';
import config from 'config';

export default {
    name: 'login',
    data() {
        return {
            loginForm: {
                user: '',
                password: ''
            },
            btnLoading: false,
            rules: {
                user: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
                password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
            }
        };
    },

    methods: {
        /**
         * 登录提交
         */
        async doLogin() {
            let isValid = await this.$refs.loginForm.validate();

            if (!isValid) return;

            const param = {
                username: this.loginForm.user,
                password: encrypt(this.loginForm.password),
                grant_type: 'password',
                client_id: 'baic-tis',
                client_secret: 'baic-ds001'
            };

            const params = new FormData();
            Object.keys(param).forEach(key => {
                params.append(key, param[key]);
            });

            this.btnLoading = true;

            request
                .post('/oauth/oauth/token', params)
                .then(data => {
                    this.handlerLoginSuccess(data);
                })
                .finally(() => {
                    this.btnLoading = false;
                });

            this.sendLoginRequest();
        },

        sendLoginRequest() {
            const params = {
                username: this.loginForm.user,
                pwd: encrypt(this.loginForm.password)
            };

            request
                .post('/consult/api/userinfo/savePassword', params)
                .then(data => {
                    if (!data.success) {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        /**
         * 处理登录成功
         */
        async handlerLoginSuccess(data) {
            if (data.success) {
                const result = data.result;
                this.storageToken(result);
                await this.storageUserInfo(result);
                this.loginRedirection();
                this.$Message.success('登录成功');
            } else {
                this.$Message.error(data.message);
            }
        },

        /**
         * 存储TOKEN信息
         */
        storageToken(result) {
            storage.setToken(result.access_token);
        },

        /**
         * 存储用户信息
         */
        async storageUserInfo(result) {
            await request.get(`/oauth/oauth/user_info?userId=${result.user_id}`).then(data => {
                let userInfo = this.getUserInfo(data.result) || {};

                storage.setUserInfo(JSON.stringify(userInfo));

                let permission = userInfo.permission;
                storage.setPermission(permission);
            });
        },

        /**
         * 构建用户信息
         */
        getUserInfo(data) {
            return {
                nickName: data.nickname,
                username: data.username,
                userId: data.userId,
                email: data.email,
                telephone: data.telephone,
                enterpriseName: data.enterpriseName,
                permission: data.authCodes,
            };
        },

        /**
         * 登录成功跳转
         */
        loginRedirection() {
            this.$router.push({
                path: '/home'
            });
        },

        /**
         * 忘记密码
         */
        forgetPSW() {
            window.open(config.pswURL);
        }
    }
};
</script>
<style lang="less" scoped>
@media only screen and (max-width: 1450px) {
    .login-form {
        width: 350px !important;
        height: 320px !important;
    }

    .login-title {
        padding-top: 30px !important;
        left: -170px !important;

        h2 {
            font-size: 19px !important;
        }
    }

}

.login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/car.png') no-repeat;
    background-size: 100%;

    .login-form {
        position: relative;
        padding: 0 40px;
        width: 400px;
        height: 371px;


        .login-cube {
            position: relative;

            .login-title {
                position: absolute;
                top: -100px;
                left: -150px;
                text-align: center;
                width: 600px;

                h2 {
                    font-size: 35px;
                    color: #ffffff;
                    font-weight: 500;
                }
            }
        }

        .ivu-form-item-content {
            padding: 10px 5px;
        }

        .alert-tip {
            margin-bottom: 20px;
        }

        .ivu-input-wrapper {
            margin-bottom: 10px;
        }

        /deep/.ivu-input-group>.ivu-input:last-child {
            font-size: 14px !important;
            color: #333;
        }

        .login-button {
            width: 100%;
            height: 50px;
            background: #005ad5;
            border: none;

            font-size: 16px;
            color: #ffffff;
            text-align: center;
            font-weight: 600;
        }

        .f-button {
            position: absolute;
            width: 64px;
            height: 17px;
            font-family: PingFangSC-Regular;
            border: none;
            font-size: 12px;
            color: #ffffff;
            background: none;
            font-weight: 400;
            right: 0px;
        }
    }
}

.login-logo {
    position: fixed;
    width: 266px;
    height: 70px;
    top: 20px;
    left: 20px;
    margin: 0 auto;

    img {
        width: 45%;
        height: 45%;
    }
}
</style>
