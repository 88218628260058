<template>
    <div class="right-wrap">
        <!-- 工具栏 -->
        <div class="toolbar-wrap">
            <!-- 上页 -->
            <!-- <i class="iconfont icon-up-arrow" title="上一页" @click="handleIconClick('up-page')" /> -->

            <!-- 下页 -->
            <!-- <i class="iconfont icon-down-arraw" title="下一页" @click="handleIconClick('down-page')" /> -->

            <!-- 放大 -->
            <i class="basefont icon-zoomin" title="放大" @click="handleIconClick('zoom-in')" />

            <!-- 缩小 -->
            <i class="basefont icon-zoomout" title="缩小" @click="handleIconClick('zoom-out')" />

            <!-- 下载 -->
            <!-- <i class="iconfont icon-download" @click="download" /> -->

            <!-- 打印 -->
            <!-- <span>
                <i class="iconfont icon-print" title="打印" @click="handleIconClick('print')" />
            </span> -->
        </div>

        <!-- 手册内容 -->
        <div class="content-wrap">

            <div class="video" v-if="path">
                <p class="text" v-if="$route.query.manualFileType == 'png'">点击图片可放大查看</p>
                <video v-if="['mp4', 'MP4', 'WebM', 'Ogg', 'webm', 'ogg'].includes($route.query.manualFileType)" :src="path"
                    controls="controls">
                    您的浏览器不支持 video 标签。
                </video>
                <div v-else-if="$route.query.manualFileType != 'png'" class="title">视频格式不支持</div>
                <img v-viewer v-if="$route.query.manualFileType == 'png'" :src="path" />
            </div>

            <div v-else>请选择数据</div>

            <!-- 测试用 -->
            <!-- <iframe
                id="iframe"
                src="https://res2.dev.servision.com.cn/projects/usersmanual/test/avatr/topics/c.html?mode=inner"
            /> -->
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueViewer from 'v-viewer';

VueViewer.setDefaults({
    toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 1,
        play: {
            show: 1,
            size: 'large'
        }
    },
    loop: false,
    title: false
});
Vue.use(VueViewer);

export default {

    components: {},

    data() {
        return {
            isLeaf: false,
            path: '',
        };
    },

    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },

    created() {
        this.$eventBus.$on('optionParams', record => {
            this.path = record.path;
        });
        this.listenerEvents();
    },

    watch: {
        data: {
            handler(val) {
                this.path = val.path;
            },
            immediate: true
        }
    },
    methods: {
        download() {
            window.open(this.path, '_blank');
        },

        listenerEvents() {
            window.addEventListener(
                'message',
                event => {
                    this.handleIframePageEvent(event.data);
                },
                false
            );
        },

        handleIframePageEvent(data) {
            const { eventName, params } = data;
            switch (eventName) {
                case 'openPage':
                    this.openManualPage(params.url);
                    break;
                default:
                    break;
            }
        },

        getPermission(type) {
            const manualType = this.$route.query.manualType;

            const data = {
                'services-manual': {
                    print: 'servicesManual:print'
                },
                'split-manual': {
                    print: 'splitManual:print'
                },
                'circuit-diagram': {
                    print: 'circuitDiagram:print'
                }
            };

            return data[manualType][type];
        },

        handleIconClick(action) {
            switch (action) {
                case 'print':
                    this.doPrintPage();
                    break;
                default:
                    this.$eventBus.$emit('onManualContentToolbarClick', action);
                    break;
            }
        },

        doPrintPage() {
            this.postMessageToIframe('PRINT');
        },

        postMessageToIframe(eventName) {
            const iframeWindow = document.getElementById('iframe').contentWindow;

            iframeWindow.postMessage(
                {
                    eventName: eventName
                },
                '*'
            );
        }
    }
};
</script>

<style lang="less" scoped>
.right-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 5px;

    .toolbar-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5px;
        padding: 5px;
        border-bottom: 1px solid @border-color;

        i {
            cursor: pointer;
            margin-left: 15px;
            font-size: 18px;
        }

        .icon-print {
            color: black;
        }
    }

    .content-wrap {
        flex: 1;
        max-height: calc(100% - 44px);
        width: 100%;

        .video {
            height: 100%;
            width: 100%;
            text-align: center;

            .text {
                color: red;
                font-size: 16px;
            }

            video {
                width: 80%;
                height: auto;
                margin: 0 auto;
                display: block;
                max-width: 100%;
                max-height: 90%;
                margin-top: 20px;
            }

            img {
                width: auto;
                height: auto;
                margin: 0 auto;
                display: block;
                max-width: 100%;
                max-height: 90%;
                object-fit: contain;
            }
        }
    }
}

.title {
    margin: 0 auto;
    font-size: 18px;
    color: red;
    margin-top: 20px;
    font-weight: 700;
}
</style>
