<template>
    <div class="left-wrap">
        <!-- 抬头区域 -->
        <div class="header-wrap">
            <span class="title-wrap">手册目录</span>
            <span class="btns-wrap">
                <i class="iconfont icon-up-arrow2" @click="handleExpandAll(false)" title="全部收起" />
                <i class="iconfont icon-down-arraw2" @click="handleExpandAll(true)" title="全部展开" />
            </span>
        </div>

        <!-- 目录树 -->
        <div class="tree-wrap">
            <SvTree ref="tree" :data="list" @onSelectNode="handleSelectNode" :isExpandAll="isExpandAll" />
            <Spin size="large" fix v-if="showLoading"></Spin>
        </div>
    </div>
</template>

<script>
import { request } from '@/common';
import { storage } from '@/common/storage';

export default {
    components: {},
    data() {
        const { code } = this.$route.query;

        return {
            showLoading: false,
            list: [],
            keyword: '',
            doSearch: null,
            code: code,
            vin: '',
            isExpandAll: false,
            params: {},
            record: {}
        };
    },
    created() {
        this.loadData();

        this.doSearch = this.getDebounce(this.doFilter, 500);

        this.$eventBus.$on('onClickSearchListItem', item => {
            this.$refs.tree.selectionNode(item.id);
        });

        this.$eventBus.$on('onManualContentToolbarClick', action => {
            if (action === 'up-page') {
                this.$refs.tree.upMoveNode();
            }
            if (action === 'down-page') {
                this.$refs.tree.downMoveNode();
            }
        });
    },

    mounted() {
        if (this.$route.query.path) {
            let record = {};
            record['id'] = this.$route.query.id;
            record['manualFileType'] = this.$route.query.manualFileType;
            record['path'] = this.$route.query.path;

            this.$set(record, 'selected', true);
            this.$refs.tree.handleOnSelectNode(record);
        }

        if (this.$route.query.access_token) {
            const token = this.$route.query.access_token;

            storage.removeStorage();
            storage.setToken(token);
        }
    },

    watch: {
        record() {
            this.$refs.tree.setSelecteNode(this.record);
        }
    },
    methods: {
        handleSearch(params) {
            this.params = params;
            this.loadData();
        },

        async loadData() {
            const url = `/manual/doc/${this.$route.query.id}/detail`;

            this.showLoading = true;

            await request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.list.push(data.result);
                        this.list.map(item => {
                            item['title'] = item.name;
                            item['selected'] = true;
                            this.handleSelectNode(item)
                        });

                        if (this.$route.query.path) {
                            this.list.filter(item => {
                                if (item.id == this.$route.query.id) {
                                    item['selected'] = true;
                                }
                            });
                        }
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        doFilter() {
            this.$refs.tree.filter(this.keyword);
        },

        handleSelectNode(record) {
            this.record = record;
            const nodes = this.$refs.tree.getSelectionNodePath(record.code);
            const nodePaths = nodes.map(item => item.title);

            this.$emit('selectNode', record, nodePaths, this.params);
        },

        handleExpandAll(expand) {
            this.isExpandAll = expand;
        },

        getSelectionNodePath(data, code) {
            let nodes = [];

            for (let i = 0; i < data.length; i++) {
                let node = data[i];
                let subNodes = [];
                let children = node.children;

                if (children && children.length > 0) {
                    subNodes = this.getSelectionNodePath(children, code);
                }

                if (subNodes.length > 0) {
                    nodes.push(node.title);
                    nodes = nodes.concat(subNodes);
                } else if (node.code === code) {
                    nodes.push(node.title);
                }
            }

            return nodes;
        }
    }
};
</script>

<style lang="less" scoped>
.left-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    .header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        padding: 5px;
        border-bottom: 1px solid @border-color;

        .title-wrap {
            font-size: 18px;
            font-weight: 700;
        }

        .btns-wrap {
            i {
                cursor: pointer;
            }

            .icon-expand {
                margin-right: 5px;
            }

            .ivu-poptip {
                margin-left: 5px;
            }
        }
    }

    .search-wrap {
        display: flex;
        padding: 5px;

        .search-box-wrap {
            flex: 1;
        }

        .advanced-search-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            margin-left: 3px;
        }
    }

    .tree-wrap {
        position: relative;
        padding: 5px;
        flex: 1;
        overflow: hidden;

        .sv-tree-wrap {
            overflow: hidden;
            overflow-y: auto;
            border: 1px solid @border-color;
            height: 100%;
        }
    }
}
</style>
