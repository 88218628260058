<template>
    <div class="vertical-crumb">
        <div class="tree-title">分组</div>
        <div class="tree-wrapper">
            <Tree :data="treeData" :render="renderContent" expand-node></Tree>
            <Spin size="large" fix v-if="treeShow"></Spin>
        </div>
    </div>
</template>
<script>
import { request } from '@/network/request';

export default {
    name: 'VerticalCrumb',
    props: {},
    data() {
        return {
            treeData: [],
            treeShow: false
        };
    },
    mounted() {
        this.getTreeList();
    },
    methods: {
        getTreeList() {
            this.treeShow = true;
            let params = {
                seriesCode: this.$route.query.seriesCode
            };

            if (this.$route.query.modelCode && this.$route.query.modelCode !== 'all') {
                params['modelCode'] = this.$route.query.modelCode;
            }

            if (this.$route.query.vin) {
                params['vinCode'] = this.$route.query.vin;
            }

            if (this.$route.query.isRecordClick) {
                params['isRecordClick'] = this.$route.query.isRecordClick;
            }

            request
                .req({
                    method: 'POST',
                    url: '/legend/modelTree',
                    data: params
                })
                .then(data => {
                    if (data.success) {
                        let groupList = data.result || [];
                        for (let i = 0; i < groupList.length; i++) {
                            if (this.$route.query.groupCode == groupList[i].code) {
                                groupList[i]['expand'] = true;
                            }
                        }
                        this.treeData = groupList;
                        this.setScrollView();
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.treeShow = false;
                });
        },
        renderContent(h, { root, node, data }) {
            let self = this;
            let nodeClass = '';
            if (
                data.code == this.$route.query.groupCode ||
                (this.$route.query.legendCode && data.legendCode == this.$route.query.legendCode)
            ) {
                nodeClass = 'ivu-tree-title-selected ';
            } else {
                nodeClass = '';
            }

            if (data.parentCode == '-1') {
                nodeClass = nodeClass + 'ivu-tree-title-parent ';
                return h(
                    'span',
                    {
                        style: {
                            display: 'inline-block',
                            width: '100%',
                            lineHeight: '34px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            wordBreak: 'break-all'
                        },
                        attrs: {
                            title: data.name
                        },
                        class: nodeClass,
                        on: {
                            click: () => {
                                self.treeNodeClick(root, node, data);
                            }
                        }
                    },
                    [data.name]
                );
            } else {
                nodeClass = nodeClass + 'last-level ';
                return h(
                    'span',
                    {
                        style: {
                            display: 'inline-block',
                            width: '100%',
                            lineHeight: '34px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            wordBreak: 'break-all'
                        },
                        attrs: {
                            title: data.qsCode + '-' + data.name
                        },
                        class: nodeClass,
                        on: {
                            click: () => {
                                self.treeNodeClick(root, node, data);
                            }
                        }
                    },
                    [data.qsCode + '-' + data.name]
                );
            }
        },
        treeNodeClick(root, node, data) {
            if (data.parentCode == '-1') {
                let newQuery = JSON.parse(JSON.stringify(this.$route.query));
                delete newQuery.qsCode;
                delete newQuery.legendCode;
                delete newQuery.callout;
                delete newQuery.isRecordClick;
                this.$router.push({
                    name: 'group',
                    query: { ...newQuery, groupCode: data.code, groupName: data.name }
                });
            } else {
                let newQuery = JSON.parse(JSON.stringify(this.$route.query));
                delete newQuery.callout;
                delete newQuery.isRecordClick;
                this.$router.push({
                    name: 'legend',
                    query: {
                        ...newQuery,
                        groupCode: root[node.parent].node.code,
                        groupName: root[node.parent].node.name,
                        legendCode: data.legendCode,
                        legendName: data.name,
                        qsCode: data.code
                    }
                });
            }
        },
        setScrollView() {
            this.$nextTick(() => {
                let activeRows = document.querySelector('.ivu-tree-title-selected.last-level');
                let tableBody = document.querySelector('.tree-wrapper');

                if (activeRows) {
                    let scrollTop = activeRows.offsetTop - tableBody.clientHeight + 300;
                    tableBody.scrollTop = scrollTop;
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
@import '~@/less/sdk/index';

.vertical-crumb {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid @border-color;
    padding: 24px 0;
    .tree-title {
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        padding: 0 24px;
    }

    .tree-wrapper {
        height: calc(100% - 24px);
        overflow: auto;

        /deep/.ivu-tree li ul {
            padding: 0;
        }

        /deep/.ivu-tree ul li {
            margin: 0;
            position: relative;
            line-height: 34px;
            padding: 0 24px;
        }

        .ivu-tree {
            .ivu-tree-children {
                padding-left: 10px;
            }

            /deep/.ivu-tree-arrow {
                width: 16px;
                color: #9b9da9;
                line-height: 34px;

                .ivu-icon-ios-arrow-forward:before {
                    content: '\f341';
                }

                i {
                    font-size: 20px;
                    color: #b9b9b9;
                }
            }

            .ivu-tree-arrow-open i {
                transform: rotate(90deg);
            }

            /deep/.ivu-tree-title {
                height: 34px;
                &:hover {
                    background-color: transparent;
                }
            }

            /deep/.last-level {
                position: absolute;
                left: 0;
                padding-left: 30px;
                &:hover {
                    background-color: blue !important;
                    color: @font-color;
                }

                &.ivu-tree-title-selected {
                    background-color: blue;
                    color: @font-color;
                }
            }

            /deep/.ivu-tree-title-selected {
                background-color: transparent;

                font-weight: 600;
                color: @primary-color;

                &:hover {
                    background-color: transparent;
                }
            }

            /deep/.ivu-tree-title-parent {
                font-weight: 600;
            }

            .site-tree-search-value {
                color: #ff4444;
            }
        }

        /deep/.ivu-tree-empty {
            padding: 24px;
        }
    }
}
</style>
