<template>
    <div>
        <div class="search-wrap">
            <span class="key">类型</span>
            <select v-model="type" class="value" :not-found-text="notfoundText">
                <option :value="item.code" v-for="(item, index) in typeList" :key="index">
                    {{ item.name }}
                </option>
            </select>

            <span class="key">关键词</span>
            <input v-model="keyword" class="value" />

            <Button class="btn" type="primary" @click="doSearch">查询</Button>
        </div>
        <div class="grid-wrap">
            <div class="view-list-wrap">
                <div class="view-item" v-for="item in list" :key="item.id" @click="handleLink(item)">
                    <div class="image-wrap">
                        <img :src="item.path" v-defaultImg="nopic" />
                    </div>
                    <div class="title-wrap text-ellipsis" :title="item.classifyName">{{ item.classifyName }}</div>
                </div>
            </div>

            <!-- <Page
                class="page-wrap"
                :total="pagingData.total"
                :current="pagingData.page"
                :pageSize="pagingData.size"
                :pageSizeOpts="pagingData.pageSizeOpts"
                @on-change="gotoPage"
                @on-page-size-change="pageSizeOnChange"
                show-sizer
                show-total
            /> -->

            <NoRecord v-if="list.length === 0" />
        </div>
    </div>
</template>

<script>
import { request } from '@/common';
import NoRecord from '@/pages/common/noRecord.vue';
import nopic from '@/assets/images/nopic.png';

export default {
    name: 'serviceManual',
    components: {
        NoRecord
    },
    data() {
        return {
            list: [],
            filterParams: {
                entranceId: '',
                manualFileType: '',
                seriesCode: '',
                keyword: ''
            },
            pagingData: {
                page: 1,
                size: 100,
                total: 0
            },
            nopic: nopic,
            typeList: [],
            type: '',
            kwList: [],
            keyword: '',
            notfoundText: '无匹配数据'
        };
    },
    created() {
        this.filterParams = this.$route.query;
        const params = this.filterParams;
        this.loadData(params);
        this.loadType();
    },
    methods: {
        handleLink(item) {
            const { manualFileType, id } = item;

            this.$eventBus.$emit('changeManualType', item.classifyName);

            if (manualFileType === 'pdf') {
                this.$router.push({
                    path: '/serviceManual/discription',
                    query: {
                        id,
                        manualFileType,
                        entranceId: this.filterParams.entranceId,
                        seriesCode: this.filterParams.seriesCode,
                        manualTypeCode: this.$route.query.manualTypeCode
                    }
                });
            } else {
                this.$router.push({
                    path: '/serviceManual/videos',
                    query: {
                        id,
                        manualFileType,
                        entranceId: this.filterParams.entranceId,
                        seriesCode: this.filterParams.seriesCode,
                        manualTypeCode: this.$route.query.manualTypeCode
                    }
                });
            }
        },

        loadData(filterParams) {
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams(filterParams, page, size);
            this.showLoading = true;

            request
                .get('/manual/manual/list', params)
                .then(data => {
                    if (data.success) {
                        this.list = data.result.data || [];
                        this.pagingData.total = data.result.total;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        gotoPage(page) {
            this.pagingData.page = page;
            this.loadData();
        },

        pageSizeOnChange(size) {
            this.pagingData.page = 1;
            this.pagingData.size = size;
            this.loadData();
        },

        doSearch() {
            this.filterParams.manualFileType = this.type;
            this.filterParams.keyword = this.keyword;

            let params = this.filterParams;

            this.loadData(params);
        },

        loadType() {
            request
                .get('/manual/combo/manual/type')
                .then(data => {
                    if (data.success) {
                        this.typeList = data.result || [];
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        }
    }
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1450px) {
    .view-item {
        margin-left: 0px !important;

        .image-wrap {
            width: 150px !important;
            height: 150px !important;
        }

        .title-wrap {
            width: 160px;
            padding: 5px;
            font-size: 16px !important;
            color: #000000;
        }
    }
}

.search-wrap {
    padding: 12px 40px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid #d9d9d9;

    .key {
        margin: 0 10px;
    }

    .value {
        border-radius: 3px;
        margin: 0 10px;
        width: 300px;
        height: 30px;
    }

    .btn {
        margin: 0 2px 2px 20px;
    }
}

.grid-wrap {
    padding: 16px;

    .view-list-wrap {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;

        .view-item {
            margin-left: 25px;

            .image-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: @border-radius-size;
                padding: 5px;
                cursor: pointer;
                padding: 5px;
                width: 230px;
                height: 230px;

                img {
                    vertical-align: middle;
                }
            }

            .title-wrap {
                width: 230px;
                padding: 5px;
                font-size: 18px;
                color: #000000;
            }

            &:hover {
                .image-wrap {
                    border: 1px solid #616df1;
                }

                .title-wrap {
                    color: #616df1;
                }
            }
        }
    }
}

.page-wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-bottom: 10px;
}
</style>
