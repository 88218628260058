<template>
    <div class="grid-wrap">
        <div class="view-list-wrap">
            <div class="view-item" v-for="item in list" :key="item.id" @click="handleLink(item)">
                <div class="image-wrap">
                    <img :src="item.path" v-defaultImg="nopic" />
                </div>
                <div class="title-wrap text-ellipsis" :title="item.name">{{ item.name }}</div>
            </div>
        </div>
        <NoRecord v-if="list.length === 0" />
    </div>
</template>

<script>
import NoRecord from '@/pages/common/noRecord.vue';
import { request } from '@/network/request';
import nopic from '@/assets/images/nopic.png';

export default {
    name: 'serviceManual',
    components: {
        NoRecord
    },
    data() {
        return {
            list: [],
            nopic: nopic,
            pagingData: {
                page: 1,
                size: 20,
                total: 0
            },
            filterParams: {
                entranceId: '',
                seriesCode: ''
            }
        };
    },
    created() {
        this.loadData();
    },
    methods: {
        loadData() {
            const params = this.getParams();

            request
                .get('/manual/manual/entrance/list', params)
                .then(data => {
                    if (data.success) {
                        this.list = data.result.data;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        },

        getParams() {
            const { page, size } = this.pagingData;
            let params = this.getPageQueryParams({}, page, size);

            if (this.$route.query.code) {
                params = this.getPageQueryParams({ manualTypeCode: this.$route.query.code }, page, size);
            }

            return params;
        },

        handleLink(item) {
            const { id } = item;
            this.$eventBus.$emit('changeEntranceName', item.name);

            this.$router.push({
                path: '/serviceManual/modal',
                query: {
                    entranceId: id,
                    manualTypeCode: this.$route.query.code
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1450px) {
    .view-item {
        margin-left: 0px !important;

        .image-wrap {
            width: 150px !important;
            height: 150px !important;
        }

        .title-wrap {
            width: 160px;
            padding: 5px;
            font-size: 16px !important;
            color: #000000;
        }
    }
}

.grid-wrap {
    padding: 16px;

    .view-list-wrap {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;

        .view-item {
            margin-left: 25px;

            .image-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: @border-radius-size;
                padding: 5px;
                cursor: pointer;
                padding: 5px;
                width: 230px;
                height: 230px;

                img {
                    vertical-align: middle;
                }
            }

            .title-wrap {
                width: 230px;
                padding: 5px;
                font-size: 18px;
                color: #000000;
            }

            &:hover {
                .image-wrap {
                    border: 1px solid #616df1;
                }

                .title-wrap {
                    color: #616df1;
                }
            }
        }
    }
}
</style>
