<template>
    <!-- 主内容 -->
    <div class="manual-body-wrap">
        <Split v-model="split" min="300px" @on-move-start="hanldeMoveStart" @on-move-end="hanldeMoveEnd">
            <div slot="left" class="left-split-pane">
                <left @selectNode="handleTreeNodeSelect" />
            </div>
            <div slot="right" class="right-split-pane">
                <!-- 拖动层解决释放不掉问题 -->
                <div class="copy-right" v-show="isShowCopyRight" />

                <right :data="data" />
            </div>
        </Split>
    </div>
</template>

<script>
import left from './common/left.vue';
import right from './common/right.vue';
import { storage } from '@/common/storage';
import { request } from '@/network/request';

export default {
    name: '',
    components: {
        left,
        right
    },
    data() {
        return {
            split: 0.2,
            isShowCopyRight: false,
            linkUrl: '',
            data: {},
            id: ''
        };
    },
    created() {
        this.id = this.$route.query.id;

        if (this.$route.query.access_token) {
            const token = this.$route.query.access_token;

            storage.removeStorage();
            storage.setToken(token);

            this.storageUserInfo();
        }
    },

    mounted() { },

    methods: {
        storageUserInfo() {
            request.get(`/oauth/oauth/user_info?userId=${this.$route.query.user_id}`).then(data => {
                let userInfo = this.getUserInfo(data.result) || {};

                storage.setUserInfo(userInfo);
            });
        },
        getUserInfo(data) {
            return {
                nickName: data.nickname
            };
        },
        hanldeMoveStart() {
            this.isShowCopyRight = true;
        },
        hanldeMoveEnd() {
            this.isShowCopyRight = false;
        },
        handleTreeNodeSelect(item, nodePaths, optionParams) {
            this.data = item;
            this.nodePaths = nodePaths;
            this.optionParams = optionParams;
        }
    }
};
</script>
<style lang="less" scoped>
.manual-body-wrap {
    display: flex;
    flex: 1;
    border-radius: @border-radius-size;
    height: 100%;

    .left-split-pane,
    .right-split-pane {
        position: absolute;
        height: 100%;
        top: 0px;
        right: 0px;
        left: 0px;
        bottom: 0px;

        .copy-right {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            margin-left: 8px;
            opacity: 0;
        }
    }
}
</style>
