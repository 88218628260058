<template>
    <div class="page-wrapper">
        <!-- 左侧页面部分 -->
        <div class="left-pane">
            <!-- 顶部内容 -->
            <div class="banner-img">
                <img src="~@/assets/images/banner.png" alt="" />
            </div>

            <!-- 底部内容 -->
            <div class="bottom-content">
                <!-- 未处理数据 -->
                <div class="tips">
                    <div class="tip" @click="openPart()">
                        <div class="left">
                            <p class="text1">配件咨询</p>
                            <p class="text2">{{ partModule.newest }}</p>
                        </div>
                        <div class="right">
                            <p class="text3 ">
                                <span>待处理数据：</span><span class="text4 ellipsis">{{ partModule.newestName }}</span>
                            </p>
                            <p class="text3 ">
                                咨询总数：<span class="text4 ellipsis">{{ partModule.total }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="tip" @click="$router.push('/engineering')">
                        <div class="left">
                            <p class="text1">工程咨询</p>
                            <p class="text2">{{ engineModule.unReadNum }}</p>
                        </div>
                        <div class="right">
                            <p class="text3 "> <span>待处理数据：</span> <span class="text4 ellipsis">{{
                                engineModule.messageTitle
                            }}</span>
                            </p>
                            <p class="text3 ">咨询总数：<span class="text4 ellipsis">{{ engineModule.total }}</span></p>
                        </div>
                    </div>
                    <div class="tip" @click="$router.push('/boutique')">
                        <div class="left">
                            <p class="text1">最新精品及改装件</p>
                            <p class="text2">{{ boutiqueModule.newest }}</p>
                        </div>
                        <div class="right">
                            <p class="text3 "> <span>配件名称：</span> <span class="text4 ellipsis">{{
                                boutiqueModule.partName
                            }}</span></p>
                            <p class="text3 ">配件总数：<span class="text4 ellipsis">{{ boutiqueModule.total }}</span></p>
                        </div>
                    </div>
                </div>
                <!-- 模块名称 -->
                <div class="pieces">
                    <div class="piece">
                        <p>配件工具</p>
                        <div
                            class="module"
                            v-for="(item, index) in moduleList1"
                            :key="index"
                            v-permission="item.permission"
                            @click="openPage(item.path)">
                            <i :class="item.icon"></i>
                            &nbsp;&nbsp;
                            <span class="text5">{{ item.title }}</span>
                        </div>
                    </div>
                    <div class="piece">
                        <p>问答咨询</p>
                        <div
                            class="module"
                            v-for="(item, index) in moduleList2"
                            :key="index"
                            v-permission="item.permission"
                            @click="openPage(item.path)">
                            <i :class="item.icon"></i>
                            &nbsp;&nbsp;
                            <span class="text5">{{ item.title }}</span>
                        </div>
                    </div>
                    <div class="piece">
                        <p>手册资料</p>
                        <div
                            class="module"
                            v-for="(item, index) in moduleList3"
                            :key="index"
                            v-permission="item.permission"
                            @click="openPage(item.path)">
                            <i :class="item.icon"></i>
                            &nbsp;&nbsp;
                            <span class="text5">{{ item.title }}</span>
                        </div>
                    </div>
                    <div class="piece">
                        <p>通知通讯</p>
                        <div
                            class="module"
                            v-for="(item, index) in moduleList4"
                            :key="index"
                            v-permission="item.permission"
                            @click="openPage(item.path)">
                            <i :class="item.icon"></i>
                            &nbsp;&nbsp;
                            <span class="text5">{{ item.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 右侧页面部分 -->
        <div class="right-pane">
            <div class="top-pane">
                <div class="infos">
                    <div class="new-info">
                        <div class="new">
                            <Badge :count="infoListCount" key="dot" class="new-info-count">
                                <div class="iconfont icon-message"></div>
                            </Badge>
                            <div class="info">
                                <p>最新通知</p>
                                <p>系统最新通知，请注意查收</p>
                            </div>
                        </div>

                        <div class="arrow" @click="toNewInfo">
                            <span class="text6">更多</span>
                            <span class="basefont icon-more"
                                v-permission="'tis:info-message:list'"></span>
                        </div>
                    </div>
                    <div class="info-items">
                        <div class="info-item" v-for="(item, idx) in NewInfoList" :key="idx">
                            <div class="top">{{ item.sendTime }}</div>
                            <div class="bottom" v-if="!item.readTime">
                                <span class="dot"></span>
                                {{ item.messageTitle }}
                            </div>
                            <div class="bottom1" v-else>
                                <s> {{ item.messageTitle }}</s>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-pane">
                <div class="message">
                    <div class="new-mes">
                        <span class="new-message">最新消息</span>
                        <div class="arrow" @click="toMessages">
                            <span class="text6">更多</span>
                            <span class="basefont icon-more"
                                v-permission="'tis:info-notice:list'"></span>
                        </div>

                    </div>
                    <div class="mes-items">
                        <div class="mes-item" v-for="(item, idx) in MesInfoList" :key="idx">
                            <div class="top">{{ item.sendTime }}</div>
                            <div class="bottom">{{ item.messageContent }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Badge } from 'view-design';
import { request } from '@/network/request';
import config from 'config';
import { storage } from '@/common/storage';

export default {
    name: 'home',
    components: { Badge },
    data() {
        return {
            password: '',
            infoListCount: 0,
            manualList: [
                { name: '维修手册', code: '1' },
                { name: '保质手册', code: '2' },
                { name: '工时手册', code: '3' },
                { name: '用户手册', code: '4' }
            ],
            moduleList1: [
                {
                    title: 'EPC电子配件目录',
                    path:
                        config.epcURL +
                        '/login/auth?usr=' +
                        JSON.parse(storage.getUserInfo()).username +
                        '&pwd=' +
                        this.password +
                        '&target=epc&database=live',
                    icon: 'icon iconfont icon-epc',
                    permission: 'tis:epc-part:*'
                },
                // { title: '呆滞件交易平台', path: 1111,icon:'icon iconfont icon-software' },  暂时取消此部分
                { title: '精品及改装件', path: '/boutique', icon: 'icon iconfont icon-tis-boutique', permission: 'tis:quality-part:*' },
                { title: '配件工时查询', path: '/workhour', icon: 'icon iconfont icon-work-query', permission: 'tis:mh:*' },
                { title: '软件仓库', path: '/diagnosesoft', icon: 'icon iconfont icon-software', permission: 'tis:ds:*' }
            ],
            moduleList2: [
                { title: '工程咨询', path: '/engineering', icon: 'icon iconfont icon-engineering', permission: 'tis:ec:*' },
                {
                    title: 'EPC配件咨询',
                    path:
                        config.epcURL +
                        '/login/auth?usr=' +
                        JSON.parse(storage.getUserInfo()).username +
                        '&pwd=' +
                        this.password +
                        '&target=pac&database=live',
                    icon: 'icon iconfont icon-a-tis-epcconsult', permission: 'tis:epc-part-ec:*'
                },
                { title: '诊断仪反馈', path: '/diagnosereply', icon: 'icon iconfont icon-feedback', permission: 'tis:ds-feedback:*' }
            ],
            moduleList3: [
                {
                    title: '维修手册',
                    path: `/serviceManual/manual?name=维修手册&code=1`,
                    icon: 'icon iconfont icon-service-manual', permission: 'tis:manual:1'
                },
                {
                    title: '质保手册',
                    path: `/serviceManual/manual?name=质保手册&code=3`,
                    icon: 'icon iconfont icon-service-manual', permission: 'tis:manual:2'
                },
                {
                    title: '工时手册',
                    path: `/serviceManual/manual?name=工时手册&code=4`,
                    icon: 'icon iconfont icon-hour-manual', permission: 'tis:manual:3'
                },
                {
                    title: '用户手册',
                    path: `/serviceManual/manual?name=用户手册&code=2`,
                    icon: 'icon iconfont icon-user-manual', permission: 'tis:manual:4'
                },
                {
                    title: '维修视频',
                    path: `/serviceManual/manual?name=维修视频&code=5`,
                    icon: 'icon iconfont icon-service-manual', permission: 'tis:manual:5'
                },
                {
                    title: '电路图',
                    path: `/serviceManual/manual?name=电路图&code=6`,
                    icon: 'icon iconfont icon-service-manual', permission: 'tis:manual:6'
                }
            ],
            moduleList4: [
                {
                    title: '配件技术类通讯',
                    path:
                        config.epcURL +
                        '/login/auth?usr=' +
                        JSON.parse(storage.getUserInfo()).username +
                        '&pwd=' +
                        this.password +
                        '&target=technical&database=live',
                    icon: 'icon iconfont icon-part-info', permission: 'tis:epc-part-ec:*'
                },
                { title: '维修技术类通知', path: '/newInfo', icon: 'icon iconfont icon-info', permission: 'tis:info-message:*' },
                { title: '消息', path: '/messages', icon: 'icon iconfont icon-message-center', permission: 'tis:info-notice:*' }
            ],
            NewInfoList: [],
            MesInfoList: [],
            partModule: {},
            engineModule: {},
            boutiqueModule: {}
        };
    },
    created() {
        this.initPage();
    },
    mounted() { },
    watch: {
        password: {
            handler(val) {
                this.moduleList1[0].path =
                    config.epcURL +
                    '/login/auth?usr=' +
                    JSON.parse(storage.getUserInfo()).username +
                    '&pwd=' +
                    val +
                    '&target=epc&database=live';

                this.moduleList2[1].path =
                    config.epcURL +
                    '/login/auth?usr=' +
                    JSON.parse(storage.getUserInfo()).username +
                    '&pwd=' +
                    val +
                    '&target=pac&database=live';

                this.moduleList4[0].path =
                    config.epcURL +
                    '/login/auth?usr=' +
                    JSON.parse(storage.getUserInfo()).username +
                    '&pwd=' +
                    val +
                    '&target=technical&database=live';
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        initPage() {
            this.getNewInfo();
            this.getMesInfo();
            this.getPartModule();
            this.getPsw();
            this.getEngineModule();
            this.getBoutiqueModule();
        },

        openPart() {
            window.open(this.moduleList2[1].path);
        },

        toNewInfo() {
            let routeData = this.$router.resolve({ path: '/newInfo?isRead=否&hiddenSourceType=true' });
            window.open(routeData.href, '_blank');
        },

        toMessages() {
            let routeData = this.$router.resolve({ path: '/messages' });
            window.open(routeData.href, '_blank');
        },

        async openPage(path) {
            if (!path) return;

            const arr = path.split('http');

            if (arr.length > 1) {
                window.open(path);
            } else {
                let routeData = this.$router.resolve({ path: path });
                window.open(routeData.href, '_blank');
            }
        },

        async getPsw() {
            const url = '/consult/userinfo/getPassword';

            await request
                .get(url)
                .then(data => {
                    if (data.success) {
                        this.password = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        // 获取最新通知
        getNewInfo() {
            const params = this.getParams();

            request
                .get('/consult/info/message/page', params)
                .then(data => {
                    if (data.success) {
                        this.NewInfoList = data.result.list;
                        this.NewInfoList.filter(item => {
                            if (!item.readTime) {
                                this.infoListCount += 1;
                            }
                        });
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        // 获取最新消息
        getMesInfo() {
            const params = this.getParams();

            request
                .get('/consult/info/message/list', params)
                .then(data => {
                    if (data.success) {
                        this.MesInfoList = data.result.list;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getPartModule() {
            const url = '/mh/epc/consult/question';
            const params = this.getParams();

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.partModule = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getEngineModule() {
            const url = '/consult/info/message/EngineeringConsult';
            const params = this.getParams();

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.engineModule = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getBoutiqueModule() {
            const url = '/mh/quality/part/newest';
            const params = this.getParams();

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.boutiqueModule = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getManualList() {
            const url = '/manual/manual/entrance/manual/type';
            const params = this.getParams();

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.manualList = data.result;
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => { });
        },

        getParams() {
            const params = this.getPageQueryParams({}, 1, 10);

            return params;
        }
    }
};
</script>

<style lang="less" scoped>
@media only screen and (max-width: 1450px) {
    .text5 {
        font-size: 13px !important;
    }

    .text1,
    .text6,
    .text3,
    .text4 {
        font-size: 12px !important;
    }

    .tip .left .text2 {
        font-size: 30px !important;
    }

    .tip .right .text3 {
        max-width: 160px;
    }

}

.page-wrapper {
    display: flex;
    height: 100%;

    .left-pane {
        flex: 4;
        display: flex;
        flex-direction: column;

        .banner-img {
            img {
                width: 100%;
                height: 100%;
            }
        }

        .bottom-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            .tips {
                flex: 1;
                display: flex;
                border-bottom: 1px @border-color solid;

                .tip {
                    display: flex;
                    justify-content: space-between;
                    flex: 1;
                    border-radius: 4px;
                    margin: 8px 12px;
                    padding: 6px 20px;
                    cursor: pointer;

                    .left {
                        text-align: left;

                        .text1 {
                            font-size: 14px;
                            color: #ffffff;
                            font-weight: 600;
                        }

                        .text2 {
                            font-size: 48px;
                            color: #ffffff;
                        }
                    }

                    .right {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        text-align: right;

                        .text3 {
                            font-size: 13px;
                            color: #f1ecec;
                        }

                        .text4 {
                            font-size: 13px;
                            color: #ffffff;
                        }
                    }
                }

                .tip:last-child {
                    margin-right: 0;
                }

                .tip:nth-child(1) {
                    background: #005ad5;
                }

                .tip:nth-child(2) {
                    background: #d50037;
                }

                .tip:nth-child(3) {
                    background: #186d88;
                }
            }

            .pieces {
                flex: 4;
                display: flex;
                flex-wrap: wrap;

                p {
                    font-size: 16px;
                    color: #606b81;
                    font-weight: 400;
                    margin: 10px 0;
                }

                .piece {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    border-radius: 4px;
                    margin-left: 12px;
                    padding-right: 20px;
                    border-right: 1px @border-color solid;

                    .module {
                        display: flex;
                        height: 72px;
                        padding: 14px;
                        padding-top: 20px;
                        margin-bottom: 8px;
                        border-radius: 2px;
                        background-color: #f4f7f9;
                        cursor: pointer;

                        .icon {
                            text-align: center;
                            width: 45px;
                            height: 45px;
                            margin: -7px 0 0 0;
                            font-size: 25px;
                            border-radius: 6px;
                            line-height: 45px;
                            color: #677abd;
                            background-color: #e8ebee;
                        }

                        .text5 {
                            line-height: 34px;
                            font-size: 17px;
                            color: #001467;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .right-pane {
        flex: 1;
        display: flex;
        background-color: #f4f7f9;
        flex-direction: column;
        padding-left: 10px;

        .top-pane {
            flex: 1;
            min-height: 260px;
            background-color: #fff;
            margin: 10px;
            margin-left: 0px;
            overflow-y: hidden;

            .infos {
                padding: 10px 15px;

                .new-info {
                    display: flex;
                    justify-content: space-between;

                    .new {
                        display: flex;
                        align-items: center;
                    }

                    .iconfont {
                        position: relative;
                        font-size: 50px;
                        color: #005ad5;
                        text-align: center;
                        vertical-align: middle;

                        .num {
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            background-color: red;
                            border-radius: 10px;
                            font-size: 10px;
                            right: -7px;
                            top: 6px;
                            color: #fff;
                        }
                    }

                    .info {
                        font-size: 17px;
                        color: #001467;
                        font-weight: 600;
                        margin-left: 10px;

                        p {
                            margin: 0;
                        }

                        p:nth-child(2) {
                            margin: 4px 0;
                            font-size: 11px;
                            color: #606b81;
                            font-weight: 500;
                        }
                    }

                    .arrow {
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        color: #001467;
                        cursor: pointer;

                        .text6 {
                            font-size: 15px;
                            color: #001467;
                            font-weight: 600;
                            margin-bottom: 2px;
                        }

                        .icon-more {
                            font-size: 12px;
                            color: #001467;
                            margin-left: 5px;
                            cursor: pointer;
                        }
                    }
                }

                .info-items {
                    max-height: 440px;
                    overflow-y: hidden;

                    .info-item {
                        font-size: 11px;
                        padding-bottom: 5px;
                        border-bottom: 1px @border-color solid;

                        .top {
                            text-align: center;
                            margin-top: 3px;
                        }

                        .bottom {
                            font-size: 14px;
                            color: #001467;
                            font-weight: 600;
                            margin-top: 2px;

                            .dot {
                                display: inline-block;
                                width: 8px;
                                height: 8px;
                                border-radius: 4px;
                                background: #e74141;
                            }
                        }

                        .bottom1 {
                            font-size: 14px;
                            color: #747499;
                            font-weight: 600;
                            margin-top: 2px;

                            .dot {
                                display: inline-block;
                                width: 8px;
                                height: 8px;
                                border-radius: 4px;
                                background: #e74141;
                            }
                        }
                    }
                }
            }
        }

        .bottom-pane {
            flex: 1;
            min-height: 360px;
            overflow-y: hidden;
            background-color: #fff;
            margin-right: 10px;

            .message {
                padding: 10px 15px;

                .new-mes {
                    display: flex;
                    justify-content: space-between;
                    font-size: 15px;
                    color: #00176b;
                    font-weight: 600;
                    padding-bottom: 4px;
                    border-bottom: 1px @border-color solid;

                    .new-message {
                        display: flex;
                        align-items: center;
                    }

                    .arrow {
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        color: #001467;
                        cursor: pointer;

                        .text6 {
                            font-size: 15px;
                            color: #001467;
                            font-weight: 600;
                            margin-bottom: 2px;
                        }

                        .icon-more {
                            font-size: 12px;
                            color: #001467;
                            margin-left: 5px;
                            cursor: pointer;
                            font-weight: 400;
                        }
                    }
                }

                .mes-item {
                    padding: 6px 0;
                    border-bottom: 1px @border-color solid;

                    .top {
                        font-size: 12px;
                    }

                    .bottom {
                        font-size: 15px;
                        color: #001467;
                        font-weight: 600;
                        margin-top: 2px;
                    }
                }
            }
        }
    }
}

/deep/.ivu-badge-count {
    top: 5px;
    height: 15px;
    line-height: 13px;
    padding: 0 5px;
}
</style>
