<template>
    <div class="file-wrap" :style="style">
        <a class="download" title="下载附件" :href="prefixSrc + src" target="_blank" download v-if="isShowDownload">
            下载附件
        </a>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: () => 200
        },
        height: {
            type: Number,
            default: () => 20
        },
        prefixSrc: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        },
        isShowDownload: {
            type: Boolean,
            default: () => true
        }
    },
    computed: {
        style() {
            return 'width:' + this.width + 'px;height:' + this.height + 'px';
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.file-wrap {

   
}
</style>