<template>
    <div class="content-wrap">
        <video :src="$route.query.url" v-if="videoTypeList.includes($route.query.type)" controls="controls"></video>
        <img :src="$route.query.url" v-else />
    </div>
</template>
<script>
import watermark from '@/common/watermark';
import { storage } from '@/common/storage';
export default {
    data() {
        return {
            videoTypeList: ['mp4', 'avi', 'mov', 'mkv', 'webm', 'mp3', 'ogg']
        };
    },
    mounted() {
        if (!document.querySelector('.watermark')) {
            let userInfo =
                Object.keys(storage.getUserInfo()).length == 0
                    ? { enterpriseName: '', nickName: '' }
                    : JSON.parse(storage.getUserInfo());

            let date = this.imestampToDate();

            //添加水印
            watermark.set(userInfo.enterpriseName + '-' + userInfo.nickName, date,'#bbb');
        }
    },
    methods: {
        imestampToDate() {
            let date1 = new Date();
            return date1.toLocaleDateString().replace(/\//g, '-') + ' ' + date1.toTimeString().substr(0, 8);
        }
    }
};
</script>
<style lang="less" scoped>
.content-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img,
    video {
        width: 80%;
        height: 80%;
    }
}
</style>
