<template>
    <div class="content-wrap">
        <!-- 查询区域 -->
        <sv-search
            :labelWidth="100"
            @onSearch="doSearch"
            ref="search"
            :hasMore="false"
            @onRadioChange="onRadioChange"
            @doReset="doReset">
            <item label="类别分类" type="combobox" url="/mh/quality/part/categoryClassify" name="categoryClassify" />
            <item
                label="配件分类"
                type="combobox"
                url="/mh/quality/part/partClassify"
                name="partClassify"
                :autoLoad="false"
                :expandLoad="true"
                dependParamsKey="category"
                dependParams="categoryClassify" />
            <item label="车型" type="combobox" url="/mh/quality/part/models" name="modelPlatformName" />
            <item label="配件号" type="textbox" name="partCode" />
            <item label="是否为新件" type="radiogroup" name="isNew" :group="['是', '否']" />
            <item label="配件名称" type="textbox" name="partName" />
        </sv-search>
        <!-- 表格区域 -->
        <sv-grid ref="grid" listTitle="精品件查询结果" :rownumber="true" @getDataList="getDataList">
            <div slot="grid-top" class="total">
                <div class="data">
                    共<span class="total-num">{{ total }}</span> 条数据
                </div>
                <div class="chooce">
                    <span>筛选项：</span> <span>
                        <Tag v-if="isNew" type="border" closable>是否为新件：{{ isNew }}</Tag>
                    </span>
                </div>
            </div>
            <urls>
                <item name="read" url="/mh/quality/part/page" />
                <item name="export" url="/mh/export/quality_part" />
            </urls>
            <toolbars>
                <item name="export" icon="md-excel" text="导出" />
            </toolbars>
            <columns>
                <item title="图片" key="" width="140px" :render="picRender" />
                <item title="类别分类" key="categoryClassifyName" width="120px" />
                <item title="配件分类" key="partClassify" width="120px" />
                <item title="车型" key="modelPlatformName" width="120px" />
                <item title="配件名称" key="partName" width="140px" />
                <item title="配件编号" key="partCode" width="120px" />
                <item title="单车用量" key="consumptionPerCar" width="120px" />
                <item title="参考价格" key="referencePrice" width="120px" />
                <item title="最小起订量" key="minOrderQuantity" width="120px" />
                <item title="用法备注" key="keyword" width="120px" />
                <item title="操作" key="" flex="1" :render="getOperateRender" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';
import { request } from '@/network/request';
import nopic from '@/assets/images/nopic.png';

export default {
    mixins: [crud],
    data() {
        return {
            total: 0,
            isNew: ''
        };
    },
    created() { },
    mounted() {
        this.initComps();
        this.$refs.grid.$on('export', () => {
            this.$refs.search.$refs.searchForm.validate().then(isValid => {
                if (isValid) {
                    const params = this.$refs.search.getParams();
                    this.$refs.grid.exportRecord(params);
                } else {
                    this.$Message.error('导出数据查询条件必填项不能为空!');
                }
            });
        });
    },
    methods: {

        initComps() {
            this.$refs.grid.$on('rowDblClick', record => {
                this.$router.push({
                    path: '/boutique/detail',
                    query: {
                        id: record[0].id
                    }
                });
            });
        },
        // 获取图片render函数
        picRender(h, { row }) {
            if (row.attachments.length == 0) {
                return h(
                    'img',
                    {
                        directives: [
                            {
                                name: 'default-img',
                                value: nopic
                            }
                        ],
                        style: {
                            width: '50px',
                            height: '50px',
                            borderRadius: '5%',
                            marginTop: '10px'
                        }
                    },
                    []
                );
            } else {
                return h(
                    'Tooltip',
                    {
                        props: {
                            placement: 'right-start',
                            transferClassName: 'tooltip'
                        }
                    },
                    [
                        h('img', {
                            attrs: {
                                src: row.attachments[0].path
                            },
                            style: {
                                width: '50px',
                                height: '50px',
                                borderRadius: '5%',
                                marginTop: '10px'
                            }
                        }),
                        h(
                            'div',
                            {
                                slot: 'content'
                            },
                            [
                                h('img', {
                                    attrs: {
                                        src: row.attachments[0].path
                                    },
                                    style: {
                                        width: '200px',
                                        height: '200px',
                                        borderRadius: '5%',
                                        marginTop: '10px'
                                    }
                                })
                            ]
                        )
                    ]
                );
            }


        },
        getOperateRender(h, { row }) {
            return h(
                'a',
                {
                    on: {
                        click: () => {
                            this.addShoppingCart(row);
                        }
                    }
                },
                '加入购物车'
            );
        },
        async addShoppingCart(row) {

            const params = { ...row, qualityId: row.id };

            request.post('/mh/shopping/cart/add', params).then(data => {
                if (data.success) {
                    this.$Message.success('加入购物车成功');
                    this.$eventBus.$emit('refreshCart');
                } else {
                    this.$Message.error(data.message);
                }
            });
        },
        getDataList(data) {
            if (data.success) {
                this.total = data.result.total;
            }
        },
        onRadioChange(data) {
            this.isNew = data;
        },
        doReset() {
            this.isNew = '';
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    padding: 0 20px;

    /deep/.query-inner {
        padding: 8px 0px;
        border-bottom: 1px @border-color solid;
    }

    /deep/.ivu-row {
        margin-top: 2px;
    }

    /deep/.grid-title {
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn-default {
        color: #005ad5;
        line-height: 20px;
    }

    /deep/.ivu-table-cell {
        line-height: 38px;
    }

    .total {
        display: flex;
        margin-left: 15px;
        margin-top: 10px;
        font-size: 13px;
        font-family: PingFangSC-Regular;

        .data {
            padding-right: 10px;
            line-height: 22px;

            .total-num {
                color: #bf2529;
            }
        }

        .chooce {
            /deep/ .ivu-tag {
                background-color: #dbe6f4 !important;
                margin: 0;

                .ivu-tag-text {
                    color: #005ad5;
                }
            }
        }
    }

    /deep/.ivu-tooltip-inner {
        background-color: #ffffff;
    }
}
</style>
