<template>
    <div class="right-wrap">
        <!-- 工具栏 -->
        <div class="toolbar-wrap">
            <!-- 放大 -->
            <i class="basefont icon-zoomin" title="放大" @click="handleIconClick('zoom-in')" />

            <!-- 缩小 -->
            <i class="basefont icon-zoomout" title="缩小" @click="handleIconClick('zoom-out')" />

            <!-- 上页 -->
            <i class="iconfont icon-up-arrow" title="上一页" @click="handleIconClick('up-page')" />

            <!-- 下页 -->
            <i class="iconfont icon-down-arraw" title="下一页" @click="handleIconClick('down-page')" />

            <!-- 打印 -->
            <span v-permission="'tis:manual:print'">
                <i class="iconfont icon-Print" title="打印" @click="handleIconClick('print')" />
            </span>
        </div>

        <!-- 手册内容 -->
        <div class="content-wrap" id="content-wrap">
            <iframe
                id="iframe"
                :src="`${config.tisURL}/api/manual/doc/getHtm/${id}/index.htm?mode=inner`" />

            <!-- 测试用 -->
            <!-- <iframe
                        id="iframe"
                        src="https://res2.dev.servision.com.cn/projects/usersmanual/test/avatr/topics/c.html?mode=inner"
                    /> -->
        </div>
    </div>
</template>

<script>
import config from 'config';
import { storage } from '@/common/storage';

export default {

    components: {},

    data() {
        return {
            isLeaf: false,
            href: '',
            id: this.$route.query.id,
            iframeSrc: '',
            config: config
        };
    },

    props: {
        data: {
            type: Object,
            default: () => { }
        }
    },

    created() {
        this.$eventBus.$on('optionParams', record => {
            this.href = record.href;
        });
    },

    mounted() {
        setTimeout(() => this.initIframePage(), 2000)
    },

    watch: {
        data: {
            handler(val) {
                this.href = val.href;
            },
            immediate: true
        }
    },
    methods: {
        initIframePage() {
            const eventName = 'PAGE_CONFIG'
            const params = {
                access_token: storage.getToken(),
                lang: 'zh_CN',
                modifiedOn: '1647251422000'
            }

            this.postMessageToIframe(eventName, params);
        },

        setPageContent(params) {
            let newParams = this.getPdfAnchorParams(params);
            this.postMessageToIframe(newParams.eventName, newParams.params);
        },

        buildHtmIframeUrl(page, keywords) {
            let url = `${config.tisURL}/api/manual/doc/getHtm/${this.id}/${page}?mode=inner`;

            if (!page) {
                return "";
            }

            if (keywords && keywords.length) {
                url = url + `&keywords=${encodeURIComponent(keywords.join("-"))}`;
            }

            return url;
        },

        getPdfAnchorParams(params) {
            if (params.keywords && params.keywords.length) {
                return {
                    eventName: "SEARCH_PAGES",
                    params: {
                        num: params.page,
                        keywords: params.keywords.join(","),
                        anchor: params.anchor || "[" + params.page + ',"FitH",800]',
                    },
                };
            } else {
                return {
                    eventName: "GO_PAGE",
                    params: {
                        anchor: params.anchor || "[" + params.page + ',"FitH",800]',
                    },
                };
            }
        },

        handleIframePageEvent(data) {
            const { eventName, params } = data;
            switch (eventName) {
                case 'openPage':
                    this.openManualPage(params.url);
                    break;
                default:
                    break;
            }
        },

        getPermission(type) {
            const manualType = this.$route.query.manualType;

            const data = {
                'services-manual': {
                    print: 'servicesManual:print'
                },
                'split-manual': {
                    print: 'splitManual:print'
                },
                'circuit-diagram': {
                    print: 'circuitDiagram:print'
                }
            };

            return data[manualType][type];
        },

        handleIconClick(action) {
            switch (action) {
                case 'print':
                    this.doPrintPage();
                    break;
                case 'zoom-in':
                    this.postMessageToIframe('ZOOM_IN');
                    break;
                case 'zoom-out':
                    this.postMessageToIframe('ZOOM_OUT');
                    break;
                default:
                    this.$eventBus.$emit('onManualContentToolbarClick', action);
                    break;
            }
        },

        doPrintPage() {
            let date = this.getDate();
            let userInfo = JSON.parse(storage.getUserInfo()) || {};
            userInfo['date'] = date;
            this.postMessageToIframe('PRINT', userInfo);
        },

        getDate() {
            let date = new Date();
            let year = date.getFullYear();    //  返回的是年份
            let month = date.getMonth() + 1;  //  返回的月份上个月的月份，记得+1才是当月
            let dates = date.getDate();       //  返回的是几号

            return `${year}-${month}-${dates}`
        },

        postMessageToIframe(eventName, params) {
            const iframeWindow = document.getElementById('iframe').contentWindow;

            iframeWindow.postMessage(
                {
                    eventName: eventName,
                    params: params,
                },
                '*'
            );
        }
    }
};
</script>

<style lang="less" scoped>
.right-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 5px;

    .toolbar-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5px;
        padding: 5px;
        border-bottom: 1px solid @border-color;

        i {
            cursor: pointer;
            margin-left: 15px;
            font-size: 18px;
        }

        .icons {
            font-size: 20px;
        }

        .icon-print {
            color: black;
        }
    }

    .content-wrap {
        flex: 1;

        iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
