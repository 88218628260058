<template>
    <div class="content-wrap">
        <!-- 查询区域 -->
        <sv-search @onSearch="doSearch" ref="search" :labelWidth="100">
            <item label="车系" type="combobox" url="/mh/man/hour/seriesCombo" name="seriesName" />
            <item label="车型" type="combobox" url="/mh/man/hour/modelCombo" name="modelPlatformName" :autoLoad="false"
                :expandLoad="true"
                dependParamsKey="seriesCode"
                dependParams="seriesName" />
            <item label="配件号" type="textbox" name="partCode" />
            <item label="配件名称" type="textbox" name="partName" />
            <item label="工时代码" type="textbox" name="code" />
            <item label="工时名称" type="textbox" name="name" />
        </sv-search>
        <!-- 表格区域 -->
        <sv-grid ref="grid" listTitle="工时查询结果" :rownumber="true" @getDataList="getDataList">
            <div slot="grid-top" class="total">
                共<span class="total-num">{{ total }}</span> 条数据
            </div>
            <urls>
                <item name="read" url="/mh/man/hour/page" />
            </urls>
            <toolbars> </toolbars>
            <columns>
                <item title="工时代码" key="code" width="120px" />
                <item title="工时名称" key="name" width="140px" />
                <item title="配件编号" key="partCode" width="120px" />
                <item title="配件名称" key="partName" width="140px" />
                <item title="车系名称" key="seriesName" width="120px" />
                <item title="车系代码" key="seriesCode" width="120px" />
                <item title="车型代码" key="modelPlatformCode" width="120px" />
                <item title="车型名称" key="modelPlatformName" width="120px" />
                <item title="工时数" key="manHourNum" width="120px" />
                <item title="备注" key="remark" flex="1" />
            </columns>
        </sv-grid>
    </div>
</template>

<script>
import { crud } from '@/mixins/crud';

export default {
    mixins: [crud],
    data() {
        return {
            total: 0
        };
    },
    created() { },
    mounted() { },
    methods: {
        getDataList(data) {
            if (data.success) {
                this.total = data.result.total;
            }
        }
    }
};
</script>

<style lang="less" scoped>
.content-wrap {
    padding: 0 20px;

    /deep/.query-inner {
        border-bottom: 1px @border-color solid;
    }

    /deep/.grid-title {
        font-size: 17px;
        color: #00176b;
        font-weight: 600;
    }

    /deep/.shopping-cart-top {
        padding: 5px 100px;

        .total-info {
            display: flex;
            align-items: center;

            .total-price {
                font-size: 17px;
                color: #00176b;
                line-height: 24px;
                font-weight: 600;
                margin-right: 60px;

                .price {
                    color: #d41313;
                }
            }

            .goods {
                font-size: 17px;
                color: #00176b;
                font-weight: 600;
            }
        }
    }

    /deep/.ivu-btn-default {
        color: #005ad5;
        line-height: 20px;
    }

    .total {
        margin-left: 15px;
        font-size: 12px;
        font-family: PingFangSC-Regular;

        .total-num {
            color: #b60004;
        }
    }

}
</style>
