<template>
  <!-- 主内容 -->
  <div class="manual-body-wrap">
    <Split v-model="split"
      min="300px"
      @on-move-start="hanldeMoveStart"
      @on-move-end="hanldeMoveEnd">
      <div slot="left"
        class="left-split-pane">
        <left ref="left"
          @selectNode="handleTreeNodeSelect" />
      </div>
      <div slot="right"
        class="right-split-pane">
        <!-- 拖动层解决释放不掉问题 -->
        <div class="copy-right"
          v-show="isShowCopyRight" />

        <right ref="right"
          :data="data" />
      </div>
    </Split>
  </div>
</template>

<script>
import left from '../common/left.vue'
import right from '../common/right.vue'
import { storage } from '@/common/storage'
import { request } from '@/network/request'

export default {
  name: '',
  components: {
    left,
    right
  },
  data() {
    return {
      split: 0.2,
      isShowCopyRight: false,
      linkUrl: '',
      data: {},
      id: ''
    }
  },
  created() {
    this.id = this.$route.query.id

    if (this.$route.query.access_token) {
      const token = this.$route.query.access_token

      storage.removeStorage()
      storage.setToken(token)

      this.storageUserInfo()
    }
  },

  mounted() { },

  methods: {
    getToken() {
      const token = storage.getToken()

      return token
    },
    storageUserInfo() {
      request.get(`/oauth/oauth/user_info?userId=${this.$route.query.user_id}`).then(data => {
        if (data.success) {
          let userInfo = this.getUserInfo(data.result) || {}

          storage.setUserInfo(userInfo)
        } else {
          this.$Message.error(data.message)
        }
      })
    },
    getUserInfo(data) {
      return {
        nickName: data.nickname
      }
    },
    hanldeMoveStart() {
      this.isShowCopyRight = true
    },
    hanldeMoveEnd() {
      this.isShowCopyRight = false
    },
    handleTreeNodeSelect(item, nodePaths, optionParams) {
      this.$refs.right.setPageContent(item)
      this.data = item
      this.nodePaths = nodePaths
      this.optionParams = optionParams
      this.addView(item)
    },
    addView(item) {
      item.title = this.removeTags(item.title)
      const params = {
        manualId: item.docId,
        manualChapter: item.title
      }

      request.post('/manual/manual/add-view', params).then(() => { }).finally(() => { })

    },
    removeTags(text = '') {
      let doc = new DOMParser().parseFromString(text, 'text/html')
      return doc.body.textContent

    }
  }
}
</script>

<style lang="less" scoped>
.manual-body-wrap {
  display: flex;
  flex: 1;
  border-radius: @border-radius-size;
  height: 100%;

  .left-split-pane,
  .right-split-pane {
    position: absolute;
    height: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;

    .copy-right {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      margin-left: 8px;
      opacity: 0;
    }
  }
}
</style>
